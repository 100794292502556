import { Box, InputAdornment, Stack, Typography, useTheme } from "@mui/material";
import { CustomTextField } from "../../CustomComps/Customs";
import { EmailLogo, THETEAM } from "../../components/Icons/Icons";
import { EgButton } from "../Shared/Shared";
import { ILoginInputs } from "./Login";
import { tokens } from "../../theme";
import { useDispatch, useSelector } from "react-redux";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LoginReq } from "../../Service/models/LoginRQ";
import { useGoogleLogin } from "@react-oauth/google";
import { useForm } from "react-hook-form";
import { LoadingState, setLoadingFalse, setLoadingTrue } from "../../redux/slices/LoadingSlice";
import { FeedbacFlag } from "../../redux/slices/Feedback";
import { ForgetPassword } from "../../Service/Apis";
import { AppDispatch } from "../../redux/store";
import { GetErrorAxios } from "../../Globals";

function Confirmeemail() {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const dispatch = useDispatch<AppDispatch>();

    const MainInputStyle = { marginBottom: 20, width: '100%' };
    const LoadingFlag = useSelector(LoadingState);
    const feedbackFlag = useSelector(FeedbacFlag);
    const animationRef = useRef<HTMLDivElement>(null)

    const navigate = useNavigate();


    const [Login, setLogin] = useState<ILoginInputs>({} as ILoginInputs);


    const { register, setError, control, handleSubmit, reset, watch, formState: { errors } } = useForm<LoginReq>({
        reValidateMode: 'onBlur',
        mode: 'onBlur'
    });

    const { ref: UserNameinputRef, ...UserNameinputProps } = register("UserName", {
        required: "برجاء ادخال البريد الإلكتروني",
    });

    const onSubmit = async (data: LoginReq) => {
        try {
            dispatch(setLoadingTrue());
            await ForgetPassword({ UserName: data.UserName });
            dispatch(setLoadingFalse());
            navigate('/Messageconf');
        } catch (error) {
            return GetErrorAxios(error, dispatch)
        }
    };

    return (
        <>
            <div className='registerBG h-screen w-full flex justify-center items-center'>
                <div className='bg-[#EDF3F4] rounded-3xl w-4/6 md:w-2/6 h-[60%] flex flex-col items-center'>
                    <div className='h-1/3 p-2 mb-10 mt-10'>
                        <THETEAM />
                    </div>
                    <form className='w-4/5 h-1/2 flex flex-col justify-start items-center' onSubmit={handleSubmit(onSubmit)}>
                        <div className='w-full flex flex-row gap-4'>
                            <CustomTextField inputRef={UserNameinputRef} {...UserNameinputProps} type={'email'} InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Box marginLeft={2}>
                                            <div className='w-6 h-6'>
                                                <EmailLogo />
                                            </div>
                                        </Box>
                                    </InputAdornment>
                                ),
                            }} style={MainInputStyle} error={!!errors.UserName}
                                helperText={errors?.UserName?.message}
                                placeholder='البريد الإلكتروني' colors={colors} theme={theme} />
                        </div>

                        <div className='w-full grow flex flex-col justify-around' >
                            <div className='w-2/3 self-center'>
                                <EgButton Action={'submit'} HandleClick={() => {}}>
                                    <Stack direction={'row'} justifyContent='center'>
                                        <Typography sx={{ marginLeft: '10px' }} variant="h5" >استمر</Typography>
                                    </Stack>
                                </EgButton>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default Confirmeemail;
