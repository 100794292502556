import React, { useEffect, useState } from 'react'
import { ContactsModel } from '../../../../../Service/models/ContactsModel/ContactsMOdel'
import { ProjectModel } from '../../../../../Service/models/projects/ProjectModel'
import { GetAllUserDetail, SAVE_COMMISSION } from '../../../../../Service/Apis'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../../../../redux/store'
import { GetErrorAxios } from '../../../../../Globals'
import { DropDownData, DropTextField } from '../AppointMents'
import { CustomTextFieldFullHeight } from '../../../../../CustomComps/Customs'
import { useTheme } from '@mui/material'
import { tokens } from '../../../../../theme'
import { EgButton } from '../../../../Shared/Shared'
import { COMMISSIONS, DEAL_MODEL } from '../../../../../Service/models/DealApplication'
import { AddAction } from '../../../../../redux/slices/ErrorsSlice'
import { setLoadingFalse, setLoadingTrue } from '../../../../../redux/slices/LoadingSlice'
import { USER_Detail } from '../../../../../Service/models/UserModel/UserModel'
import InputCustom from '../../../../../components/InputType'
import { debug } from 'console'


const Percentage = ({ deal, isView, clearCommission, isEdit }: { isEdit?: boolean, deal: DEAL_MODEL, isView?: boolean, clearCommission: () => void }) => {

    const dispatch = useDispatch<AppDispatch>();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const MainInputStyle = { marginBottom: 2, width: '100%', backgroundColor: 'white' };

    const [displayCommDiff, setDisplayCommDiff] = useState<boolean>(false);

    const [internalProjs, setInternalProjs] = useState<ProjectModel[]>([]);
    const [ProjectsdropDownData, setProjectsDropDownData] = useState<DropDownData[]>([])
    const [commission, setCommission] = useState<COMMISSIONS>({
        ACTION_ID: deal.ACTION_ID,
        // PROJECT_ID: undefined,
        NAME_PERCENTAGE: undefined,
        NAME_TOTAL: undefined,
        NAME: deal.APPLICANT_NAME,
        DIRECT_PERCENTAGE: undefined,
        DIRECT_TOTAL: undefined,
        DIRECT_NAME: deal.DIRECT_USER_NAME,
        COMMISSION_DIFFERENCE_NAME: undefined,
        COMMISSION_DIFFERENCE_PERCENTAGE: undefined,
        COMMISSION_DIFFERENCE_TOTAL: undefined,
        LE1_PERCENTAGE: undefined,
        LE1_TOTAL: undefined,
        LE2_PERCENTAGE: undefined,
        LE2_TOTAL: undefined,
        LE3_PERCENTAGE: undefined,
        LE3_TOTAL: undefined,
        LE4_PERCENTAGE: undefined,
        LE4_TOTAL: undefined,
        THE_TEAM_PERCENTAGE: undefined,
        THE_TEAM_TOTAL: undefined,
        BOX_PERCENTAGE: undefined,
        BOX_TOTAL: undefined,
        COMPANY_PERCENTAGE: undefined,
        COMPANY_TOTAL: undefined,
        FOUNDER_PERCENTAGE: undefined,
        FOUNDER_TOTAL: undefined,
        DIRECT_TAX_PREC: 0,
        LVL1_TAX_PREC: 0,
        LVL2_TAX_PREC: 0,
        LVL3_TAX_PREC: 0,
        LVL4_TAX_PREC: 0,
        MANGER_PERCENTAGE: 0,
        MANGER_TOTAL: 0,
        MANGER_TAX_PREC: 0,
        THE_TEAM_TAX_PREC: 0,
        BOX_TAX_PREC: 0,
        COMPANY_TAX_PREC: 0,
        FOUNDER_TAX_PREC: 0,
        // EXTERNAL_COM1_TAX_PREC: 24,
        // EXTERNAL_COM2_TAX_PREC: 24,
    } as COMMISSIONS);
    const [commissinDisplay, setCommissionDisplay] = useState<COMMISSIONS>({
        ACTION_ID: deal.ACTION_ID,
        // PROJECT_ID: undefined,
        NAME_PERCENTAGE: 0,
        NAME_TOTAL: 0,
        NAME: deal.APPLICANT_NAME,
        DIRECT_PERCENTAGE: 0,
        DIRECT_TOTAL: 0,
        DIRECT_NAME: deal.DIRECT_USER_NAME,
        COMMISSION_DIFFERENCE_NAME: undefined,
        COMMISSION_DIFFERENCE_PERCENTAGE: 0,
        COMMISSION_DIFFERENCE_TOTAL: 0,
        LE1_PERCENTAGE: 0,
        LE1_TOTAL: 0,
        LE2_PERCENTAGE: 0,
        LE2_TOTAL: 0,
        LE3_PERCENTAGE: 0,
        LE3_TOTAL: 0,
        LE4_PERCENTAGE: 0,
        LE4_TOTAL: 0,
        THE_TEAM_PERCENTAGE: 0,
        THE_TEAM_TOTAL: 0,
        BOX_PERCENTAGE: 0,
        BOX_TOTAL: 0,
        COMPANY_PERCENTAGE: 0,
        COMPANY_TOTAL: 0,
        FOUNDER_PERCENTAGE: 0,
        FOUNDER_TOTAL: 0,
        DIRECT_TAX_PREC: 0,
        LVL1_TAX_PREC: 0,
        LVL2_TAX_PREC: 0,
        LVL3_TAX_PREC: 0,
        LVL4_TAX_PREC: 0,
        MANGER_PERCENTAGE: 0,
        MANGER_TAX_PREC: 0,
        MANGER_TOTAL: 0,
        MANGER_USER_ID: -1,
        THE_TEAM_TAX_PREC: 0,
        BOX_TAX_PREC: 0,
        COMPANY_TAX_PREC: 0,
        FOUNDER_TAX_PREC: 0,
        EXTERNAL_COM1_TAX_PREC: 0,
        EXTERNAL_COM2_TAX_PREC: 0,
    } as COMMISSIONS);

    //Users State
    const [users, setUsers] = useState<USER_Detail[]>([])
    const [userDropDown, setUserDropDown] = useState<DropDownData[]>([])

    //User Direct
    const [selectedDirect, setSelectedDirect] = useState<number>(-1)
    //User LVL 1
    const [selectedLVL1UserId, setSelectedLVL1UserId] = useState<number>(-1)
    //User LVL 2
    const [selectedLVL2UserId, setSelectedLVL2UserId] = useState<number>(-1)
    //User LVL 3
    const [selectedLVL3UserId, setSelectedLVL3UserId] = useState<number>(-1)
    //User LVL 4
    const [selectedLVL4UserId, setSelectedLVL4UserId] = useState<number>(-1)
    // manger
    const [selectedManger, setSelectedManger] = useState<number>(-1)


    const UPDATE_COMMISSION = (commissionKey: keyof COMMISSIONS, value: any) => {
        setCommission({
            ...commission,
            [commissionKey]: value
        })

    }


    const SAVE_COMMISSION_API = (req: COMMISSIONS) => {


        let isAllFieldsFilled = true;
        let updatedReq: COMMISSIONS = {
            ...req, ACTION_ID: deal.ACTION_ID, NAME: deal.APPLICANT_NAME, DIRECT_NAME: deal.DIRECT_USER_NAME, LE1_NAME: deal.LVL1_USER_NAME, LE2_NAME: deal.LVL2_USER_NAME, LE3_NAME: deal.LVL3_USER_NAME, LE4_NAME: deal.LVL4_USER_NAME,
            DIRECT_PERCENTAGE: parseFloat((req.DIRECT_PERCENTAGE ?? "").toString()),
            NAME_PERCENTAGE: parseFloat((req.NAME_PERCENTAGE ?? "").toString()),
            LE1_PERCENTAGE: parseFloat((req.LE1_PERCENTAGE ?? "").toString()),
            LE2_PERCENTAGE: parseFloat((req.LE2_PERCENTAGE ?? "0").toString()),
            LE3_PERCENTAGE: parseFloat((req.LE3_PERCENTAGE ?? 0).toString()),
            LE4_PERCENTAGE: parseFloat((req.LE4_PERCENTAGE ?? 0).toString()),
            THE_TEAM_PERCENTAGE: parseFloat((req.THE_TEAM_PERCENTAGE ?? 0).toString()),
            BOX_PERCENTAGE: parseFloat((req.BOX_PERCENTAGE ?? 0).toString()),
            COMPANY_PERCENTAGE: parseFloat((req.COMPANY_PERCENTAGE ?? 0).toString()),
            FOUNDER_PERCENTAGE: parseFloat((req.FOUNDER_PERCENTAGE ?? 0).toString()),
            EXTERNAL_COMMISSIONN_1_PRECENTAGE: parseFloat((req.EXTERNAL_COMMISSIONN_1_PRECENTAGE ?? 0).toString()),
            EXTERNAL_COMMISSIONN_2_PRECENTAGE: parseFloat((req.EXTERNAL_COMMISSIONN_2_PRECENTAGE ?? 0).toString()),
            DIRECT_TAX_PREC: parseFloat((req.DIRECT_TAX_PREC ?? 0).toString()),
            LVL1_TAX_PREC: parseFloat((req.LVL1_TAX_PREC ?? 0).toString()),
            LVL2_TAX_PREC: parseFloat((req.LVL2_TAX_PREC ?? 0).toString()),
            LVL3_TAX_PREC: parseFloat((req.LVL3_TAX_PREC ?? 0).toString()),
            LVL4_TAX_PREC: parseFloat((req.LVL4_TAX_PREC ?? 0).toString()),
            MANGER_PERCENTAGE: parseFloat((req.MANGER_PERCENTAGE ?? 0).toString()),
            MANGER_TOTAL: parseFloat((req.MANGER_TOTAL ?? 0).toString()),
            MANGER_TAX_PREC: parseFloat((req.MANGER_TAX_PREC ?? 0).toString()),
            THE_TEAM_TAX_PREC: parseFloat((req.THE_TEAM_TAX_PREC ?? 0).toString()),
            BOX_TAX_PREC: parseFloat((req.BOX_TAX_PREC ?? 0).toString()),
            COMPANY_TAX_PREC: parseFloat((req.COMPANY_TAX_PREC ?? 0).toString()),
            FOUNDER_TAX_PREC: parseFloat((req.FOUNDER_TAX_PREC ?? 0).toString()),
            EXTERNAL_COM1_TAX_PREC: parseFloat((req.EXTERNAL_COM1_TAX_PREC ?? 0).toString()),
            EXTERNAL_COM2_TAX_PREC: parseFloat((req.EXTERNAL_COM2_TAX_PREC ?? 0).toString()),
            DIRECT_USER_ID: parseInt(selectedDirect.toString()),
            LVL1_USER_ID: parseInt(selectedLVL1UserId.toString()),
            LVL2_USER_ID: parseInt(selectedLVL2UserId.toString()),
            LVL3_USER_ID: parseInt(selectedLVL3UserId.toString()),
            LVL4_USER_ID: parseInt(selectedLVL4UserId.toString()),
        }




        // dispatch(setLoadingTrue());

        if (req.DIRECT_PERCENTAGE === undefined) {
            dispatch(AddAction({ message: `برجاء إدخال نسبه المدير`, status: "error" }))
            return;
        }

        // check for direct total 
        if (req.DIRECT_TOTAL === undefined) {
            dispatch(AddAction({ message: `برجاء إدخال الإجمالي للمدير`, status: "error" }))
            return;
        }

        // check for the team percentage
        if (req.THE_TEAM_PERCENTAGE === undefined) {
            dispatch(AddAction({ message: `برجاء إدخال نسبه THE-TEAM`, status: "error" }))
            return;
        }

        // check for the team total
        if (req.THE_TEAM_TOTAL === undefined) {
            dispatch(AddAction({ message: `برجاء إدخال الإجمالي للTHE-TEAM`, status: "error" }))
            return;
        }

        // check for company percentage
        if (req.COMPANY_PERCENTAGE === undefined) {
            dispatch(AddAction({ message: `برجاء إدخال نسبه الشركه`, status: "error" }))
            return;
        }

        // check for company total 
        if (req.COMPANY_TOTAL === undefined) {
            dispatch(AddAction({ message: `برجاء إدخال الإجمالي للشركه`, status: "error" }))
            return;
        }


        // check for box percentage
        if (req.BOX_PERCENTAGE === undefined) {
            dispatch(AddAction({ message: `برجاء إدخال نسبه BOX`, status: "error" }))
            return;
        }

        // check for box total
        if (req.BOX_TOTAL === undefined) {
            dispatch(AddAction({ message: `برجاء إدخال الإجمالي للBOX`, status: "error" }))
            return;
        }



        // for (const [key, val] of Object.entries(req)) {
        //     if (val === undefined || val === null) {
        //         isAllFieldsFilled = false;
        //         dispatch(AddAction({ message: `برجاء عدم ترك خانه فارغه`, status: "error" }))
        //         break;
        //     }
        // }

        //log updatedreq 

        dispatch(setLoadingTrue());
        if (isAllFieldsFilled) {
            SAVE_COMMISSION(updatedReq).then(res => {
                dispatch(setLoadingFalse());
                dispatch(AddAction({ message: `تم اضافه النسبه بنجاح`, status: "success" }))
                setCommission({
                    ACTION_ID: deal.ACTION_ID,
                    PROJECT_ID: undefined,
                    NAME_PERCENTAGE: undefined,
                    NAME_TOTAL: undefined,
                    NAME: deal.APPLICANT_NAME,
                    DIRECT_PERCENTAGE: undefined,
                    DIRECT_TOTAL: undefined,
                    DIRECT_NAME: deal.DIRECT_USER_NAME,
                    COMMISSION_DIFFERENCE_NAME: undefined,
                    COMMISSION_DIFFERENCE_PERCENTAGE: undefined,
                    COMMISSION_DIFFERENCE_TOTAL: undefined,
                    LE1_PERCENTAGE: undefined,
                    LE1_TOTAL: undefined,
                    LE2_PERCENTAGE: undefined,
                    LE2_TOTAL: undefined,
                    LE3_PERCENTAGE: undefined,
                    LE3_TOTAL: undefined,
                    LE4_PERCENTAGE: undefined,
                    LE4_TOTAL: undefined,
                    THE_TEAM_PERCENTAGE: undefined,
                    THE_TEAM_TOTAL: undefined,
                    BOX_PERCENTAGE: undefined,
                    BOX_TOTAL: undefined,
                    COMPANY_PERCENTAGE: undefined,
                    COMPANY_TOTAL: undefined,
                    FOUNDER_PERCENTAGE: undefined,
                    FOUNDER_TOTAL: undefined,
                    DIRECT_TAX_PREC: undefined,
                    LVL1_TAX_PREC: undefined,
                    LVL2_TAX_PREC: undefined,
                    LVL3_TAX_PREC: undefined,
                    LVL4_TAX_PREC: undefined,
                    THE_TEAM_TAX_PREC: undefined,
                    BOX_TAX_PREC: undefined,
                    COMPANY_TAX_PREC: undefined,
                    FOUNDER_TAX_PREC: undefined,
                    EXTERNAL_COM1_TAX_PREC: undefined,
                    EXTERNAL_COM2_TAX_PREC: undefined,
                    MANGER_PERCENTAGE: undefined,
                    MANGER_TOTAL: undefined,
                    MANGER_TAX_PREC: undefined,
                    MANGER_USER_ID: undefined,
                    TAX_IS_PREC: false,
                } as COMMISSIONS)
                window.location.reload();
            }).catch(err => {
                dispatch(setLoadingFalse());
                GetErrorAxios(err, dispatch);
            })
        }


    }

    const textFieldData = (item: keyof COMMISSIONS) => {
        if (isView !== undefined && isView === true) {
            if (commissinDisplay) return commissinDisplay[item] ?? 0
        } else {
            if (commission) return commission[item] ?? 0
        }
    }

    useEffect(() => {
        // setCommissionDisplay(prev => ({ ...prev, ACTION_ID: deal.ACTION_ID, NAME: deal.APPLICANT_NAME, DIRECT_NAME: deal.DIRECT_USER_NAME, NAME_TOTAL: deal.CLIENT_UNIT_PRICE }))
        if (isView === true) {
            setCommissionDisplay(prev => (deal.DEAL_COMMISION!))
        }
        setSelectedDirect(deal.DIRECT_USER_ID ?? -1)
        setSelectedLVL1UserId(deal.LVL1_USER_ID ?? -1)
        setSelectedLVL2UserId(deal.LVL2_USER_ID ?? -1)
        setSelectedLVL3UserId(deal.LVL3_USER_ID ?? -1)
        setSelectedLVL4UserId(deal.LVL4_USER_ID ?? -1)
        setSelectedManger(deal.MAGER_USER_ID ?? -1)
        // if (isView !== undefined && isView === true && deal.DEAL_COMMISION !== undefined) {
        //     setCommissionDisplay(deal.DEAL_COMMISION)
        // }
        if (deal.DEAL_COMMISION !== undefined) {
            setCommission(deal.DEAL_COMMISION)
            // setSelectedDirect(deal.DIRECT_USER_ID ?? -1)
            // setSelectedLVL1UserId(deal.LVL1_USER_ID ?? -1)
            // setSelectedLVL2UserId(deal.LVL2_USER_ID ?? -1)
            // setSelectedLVL3UserId(deal.LVL3_USER_ID ?? -1)
            // setSelectedLVL4UserId(deal.LVL4_USER_ID ?? -1)
        }
    }, [deal, isView, isEdit])


    const getAllUsersData = async () => {
        let AllUsers = await GetAllUserDetail({ WithAdmin: true, WithNewUsers: true })


        // users dropdown logic 
        if (AllUsers.length > 0) {
            setUsers(AllUsers)
            let DropDownMap: DropDownData[] = AllUsers.map((item, idx) => ({ id: item.ID, desc: item.FULL_NAME }))
            setUserDropDown(DropDownMap)
        }
    }

    useEffect(() => {
        getAllUsersData()
    }, [])


    // useEffect(() => {
    //     setInternalProjs(projects)
    //     setProjectsDropDownData(projects.map((item, idx) => ({ id: item.ID, desc: item.DESCRIPTION } as DropDownData)))
    // }, [projects])



    const cellWidtString = 'min-w-[200px]'

    if (isView !== undefined && isView === true && deal.DEAL_COMMISION === undefined) {
        return <div className='w-full h-96 flex justify-center items-center'>
            <h1 className='text-gray-500'> لم يتم ادخال النسب في هذه الصفقه بعد</h1>
        </div>
    }

    return (
        <div className='w-full bg-white  rounded-2xl p-10 flex flex-col justify-center items-center relative'>
            <div className="bg-gray-500  text-white px-6 py-2 rounded-md mb-2 inline-flex items-center justify-center hover:cursor-pointer self-start" onClick={() => {
                setDisplayCommDiff(prev => !prev)
            }}>
                <p className='font-bold'> خانات اضافيه</p>
            </div>
            <div className='w-[96%] p-1 self-start  overflow-x-auto'>
                <table className='w-full' >
                    <thead>
                        <tr className=''>
                            <th className={cellWidtString}>
                                {/* <DropTextField classes='w-3/4 bg-white' data={[
                                ...ProjectsdropDownData
                            ]} onchange={e => {

                            }} /> */}
                                {deal.COMPANY_PROJECT_NAME ?? 'No Name'}
                            </th>
                            <th className={cellWidtString}>Direct</th>
                            <th className={cellWidtString}>LE.1</th>
                            <th className={cellWidtString}>LE.2</th>
                            <th className={cellWidtString}>LE.3</th>
                            <th className={cellWidtString}>LE.4</th>
                            <th className={cellWidtString}>مدير الفرع</th>
                            <th className={cellWidtString}>The Team</th>
                            <th className={cellWidtString}>Founder</th>
                            <th className={cellWidtString}>Box</th>
                            <th className={cellWidtString}>Company</th>

                            {displayCommDiff && <th className={cellWidtString}>عموله خارجيه 1</th>}
                            {displayCommDiff && <th className={cellWidtString}>عموله خارجيه 2</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {/* Users */}
                        <tr>
                            <td >
                                <div className='w-full  flex flex-col gap-1 my-2'>
                                    {deal.CLIENT_NAME}
                                    {/* <label htmlFor="Name">الإسم</label>
                                <div>
                                    <CustomTextFieldFullHeight height={25} id='Name' type={''} onChange={(e: any) => {
                                        let val = e.target.value as string;

                                    }} style={MainInputStyle}
                                        colors={colors} theme={theme} />
                                </div> */}
                                </div>
                            </td>
                            <td>
                                <div className='w-full  flex flex-col gap-1 my-2'>
                                    <>
                                        <div className='w-full flex flex-row gap-4 items-center'>
                                            <DropTextField initalValue={selectedDirect} disabled={isView} data={[
                                                { id: -1, desc: "اختار المستخدم" }, ...userDropDown
                                            ]} onchange={e => {
                                                let val = e.target.value
                                                setSelectedDirect(val)
                                            }} />
                                        </div>
                                    </>
                                    {/* {(deal.DIRECT_USER_NAME === "" || deal.DIRECT_USER_NAME === undefined) ?
                                        <>
                                            <div className='w-full flex flex-row gap-4 items-center'>
                                                <DropTextField initalValue={deal.DEAL_COMMISION?.DIRECT_USER_ID ?? -1} data={[
                                                    { id: -1, desc: "اختار مستخدم" }, ...userDropDown
                                                ]} onchange={e => {
                                                    let val = e.target.value
                                                    setSelectedDirect(val)
                                                }} />
                                            </div>
                                        </> : <>
                                            {deal.DIRECT_USER_NAME}
                                        </>
                                    } */}
                                    {/* <label htmlFor="Name2">الإسم</label>
                                <div>
                                    <CustomTextFieldFullHeight height={25} id='Name2' type={''} onChange={(e: any) => {
                                        let val = e.target.value as string;

                                    }} style={MainInputStyle}
                                        colors={colors} theme={theme} />
                                </div> */}
                                </div>
                            </td>
                            <td>
                                <div className='w-full  flex flex-col gap-1 my-2'>
                                    <div className='w-full flex flex-row gap-4 items-center'>
                                        <DropTextField initalValue={selectedLVL1UserId} disabled={isView} data={[
                                            { id: -1, desc: "اختار المستخدم" }, ...userDropDown
                                        ]} onchange={e => {
                                            let val = e.target.value
                                            setSelectedLVL1UserId(val)
                                        }} />
                                    </div>
                                    {/* {(deal.LVL1_USER_NAME === "" || deal.LVL1_USER_ID === undefined) ?
                                        <>
                                            <div className='w-full flex flex-row gap-4 items-center'>
                                                <DropTextField initalValue={deal.DEAL_COMMISION?.LVL1_USER_ID ?? -1} data={[
                                                    { id: -1, desc: "اختار مستخدم" }, ...userDropDown
                                                ]} onchange={e => {
                                                    let val = e.target.value
                                                    setSelectedLVL1UserId(val)
                                                }} />
                                            </div>
                                        </> : <>
                                            {deal.LVL1_USER_NAME}
                                        </>
                                    } */}
                                    {/* <label htmlFor="Name4">الإسم</label>
                                <div>
                                    <CustomTextFieldFullHeight height={25} id='Name4' type={''} onChange={(e: any) => {
                                        let val = e.target.value as string;

                                    }} style={MainInputStyle}
                                        colors={colors} theme={theme} />
                                </div> */}
                                </div>
                            </td>
                            <td>
                                <div className='w-full  flex flex-col gap-1 my-2'>
                                    <div className='w-full flex flex-row gap-4 items-center'>
                                        <DropTextField initalValue={selectedLVL2UserId} disabled={isView} data={[
                                            { id: -1, desc: "اختار المستخدم" }, ...userDropDown
                                        ]} onchange={e => {
                                            let val = e.target.value
                                            setSelectedLVL2UserId(val)
                                        }} />
                                    </div>

                                    {/* {(deal.LVL2_USER_NAME === "" || deal.LVL2_USER_ID === undefined) ?
                                        <>
                                            <div className='w-full flex flex-row gap-4 items-center'>
                                                <DropTextField initalValue={deal.DEAL_COMMISION?.DIRECT_USER_ID ?? -1} data={[
                                                    { id: -1, desc: "اختار مستخدم" }, ...userDropDown
                                                ]} onchange={e => {
                                                    let val = e.target.value
                                                    setSelectedLVL2UserId(val)
                                                }} />
                                            </div>
                                        </> : <>
                                            {deal.LVL2_USER_NAME}
                                        </>
                                    } */}
                                    {/* <label htmlFor="Name5">الإسم</label>
                                <div>
                                    <CustomTextFieldFullHeight height={25} id='Name5' type={''} onChange={(e: any) => {
                                        let val = e.target.value as string;

                                    }} style={MainInputStyle}
                                        colors={colors} theme={theme} />
                                </div> */}
                                </div>
                            </td>
                            <td>
                                <div className='w-full  flex flex-col gap-1 my-2'>
                                    <div className='w-full flex flex-row gap-4 items-center'>
                                        <DropTextField initalValue={selectedLVL3UserId} disabled={isView} data={[
                                            { id: -1, desc: "اختار المستخدم" }, ...userDropDown
                                        ]} onchange={e => {
                                            let val = e.target.value
                                            setSelectedLVL3UserId(val)
                                        }} />
                                    </div>
                                    {/* {(deal.LVL3_USER_NAME === "" || deal.LVL3_USER_ID === undefined) ?
                                        <>
                                            <div className='w-full flex flex-row gap-4 items-center'>
                                                <DropTextField initalValue={-1} data={[
                                                    { id: -1, desc: "اختار مستخدم" }, ...userDropDown
                                                ]} onchange={e => {
                                                    let val = e.target.value
                                                    setSelectedLVL3UserId(val)
                                                }} />
                                            </div>
                                        </> : <>
                                            {deal.LVL3_USER_NAME}
                                        </>
                                    } */}
                                    {/* <label htmlFor="Name6">الإسم</label>
                                <div>
                                    <CustomTextFieldFullHeight height={25} id='Name6' type={''} onChange={(e: any) => {
                                        let val = e.target.value as string;

                                    }} style={MainInputStyle}
                                        colors={colors} theme={theme} />
                                </div> */}
                                </div>
                            </td>
                            <td>
                                <div className='w-full  flex flex-col gap-1 my-2'>
                                    <div className='w-full flex flex-row gap-4 items-center'>
                                        <DropTextField initalValue={selectedLVL4UserId} disabled={isView} data={[
                                            { id: -1, desc: "اختار المستخدم" }, ...userDropDown
                                        ]} onchange={e => {
                                            let val = e.target.value
                                            setSelectedLVL4UserId(val)
                                        }} />
                                    </div>
                                    {/* {(deal.LVL4_USER_NAME === "" || deal.LVL4_USER_ID === undefined) ?
                                        <>
                                            <div className='w-full flex flex-row gap-4 items-center'>
                                                <DropTextField initalValue={-1} data={[
                                                    { id: -1, desc: "اختار مستخدم" }, ...userDropDown
                                                ]} onchange={e => {
                                                    let val = e.target.value
                                                    setSelectedLVL4UserId(val)
                                                }} />
                                            </div>
                                        </> : <>
                                            {deal.LVL4_USER_NAME}
                                        </>
                                    } */}
                                    {/* <label htmlFor="Name6">الإسم</label>
                                <div>
                                    <CustomTextFieldFullHeight height={25} id='Name6' type={''} onChange={(e: any) => {
                                        let val = e.target.value as string;

                                    }} style={MainInputStyle}
                                        colors={colors} theme={theme} />
                                </div> */}
                                </div>
                            </td>
                            <td>
                                {/* managers */}
                                <div className='w-full  flex flex-col gap-1 my-2'>
                                    <div className='w-full flex flex-row gap-4 items-center'>
                                        <DropTextField initalValue={selectedManger} disabled={true} data={[
                                            { id: -1, desc: "اختار المستخدم" }, ...userDropDown
                                        ]} onchange={e => {
                                            let val = e.target.value
                                            setSelectedManger(val)
                                        }} />
                                    </div>
                                    {/* {(deal.LVL4_USER_NAME === "" || deal.LVL4_USER_ID === undefined) ?
                                        <>
                                            <div className='w-full flex flex-row gap-4 items-center'>
                                                <DropTextField initalValue={-1} data={[
                                                    { id: -1, desc: "اختار مستخدم" }, ...userDropDown
                                                ]} onchange={e => {
                                                    let val = e.target.value
                                                    setSelectedLVL4UserId(val)
                                                }} />
                                            </div>
                                        </> : <>
                                            {deal.LVL4_USER_NAME}
                                        </>
                                    } */}
                                    {/* <label htmlFor="Name6">الإسم</label>
                                <div>
                                    <CustomTextFieldFullHeight height={25} id='Name6' type={''} onChange={(e: any) => {
                                        let val = e.target.value as string;

                                    }} style={MainInputStyle}
                                        colors={colors} theme={theme} />
                                </div> */}
                                </div>
                            </td>
                            <td>
                                <div className='w-full  flex flex-col gap-1 my-2'>

                                    {/* <label htmlFor="Name8">الإسم</label>
                                <div>
                                    <CustomTextFieldFullHeight disabled height={25} id='Name8' type={''} onChange={(e: any) => {
                                        let val = e.target.value as string;

                                    }} style={MainInputStyle}
                                        colors={colors} theme={theme} />
                                </div> */}
                                </div>
                            </td>
                            <td>
                                <div className='w-full  flex flex-col gap-1 my-2'>
                                    {/* <label htmlFor="Name8">الإسم</label>
                                <div>
                                    <CustomTextFieldFullHeight disabled height={25} id='Name8' type={''} onChange={(e: any) => {
                                        let val = e.target.value as string;

                                    }} style={MainInputStyle}
                                        colors={colors} theme={theme} />
                                </div> */}
                                </div>
                            </td>
                            <td>
                                <div className='w-full  flex flex-col gap-1 my-2'>
                                    {/* <label htmlFor="Name8">الإسم</label>
                                <div>
                                    <CustomTextFieldFullHeight disabled height={25} id='Name8' type={''} onChange={(e: any) => {
                                        let val = e.target.value as string;

                                    }} style={MainInputStyle}
                                        colors={colors} theme={theme} />
                                </div> */}
                                </div>
                            </td>
                            <td>
                                <div className='w-full  flex flex-col gap-1 my-2'>
                                    {/* <label htmlFor="Name8">الإسم</label>
                                <div>
                                    <CustomTextFieldFullHeight disabled height={25} id='Name8' type={''} onChange={(e: any) => {
                                        let val = e.target.value as string;

                                    }} style={MainInputStyle}
                                        colors={colors} theme={theme} />
                                </div> */}
                                </div>
                            </td>
                            {displayCommDiff && (
                                <td>

                                </td>
                            )}
                            {displayCommDiff && (
                                <td>

                                </td>
                            )}
                        </tr>
                        {/* commission Percentage */}
                        <tr>
                            <td>
                                <div className='w-full  flex flex-col gap-1 my-2'>
                                    <label htmlFor="Total">نسبه العموله الإجماليه (%)</label>
                                    <div>
                                        <CustomTextFieldFullHeight disabled={isView ?? false} value={textFieldData('NAME_PERCENTAGE') ?? ""} height={25} id='Total' type={''} onChange={(e: any) => {
                                            let val = e.target.value;
                                            // let isNumber = !isNaN(val)
                                            // if (!isNumber) {
                                            //     dispatch(AddAction({ message: "يجب ادخال رقم فقط", status: "error" }))
                                            // }



                                            UPDATE_COMMISSION('NAME_PERCENTAGE', val);

                                        }} style={MainInputStyle}
                                            colors={colors} theme={theme} />
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className='w-full  flex flex-col gap-1 my-2'>
                                    <label htmlFor="DirectPer">النسبه المؤيه (%)</label>
                                    <div>
                                        <CustomTextFieldFullHeight disabled={isView ?? false} value={textFieldData('DIRECT_PERCENTAGE') ?? ""} height={25} id='DirectPer' type={''} onChange={(e: any) => {
                                            let val = e.target.value;
                                            setCommission(prev => ({ ...prev, DIRECT_PERCENTAGE: val, DIRECT_TOTAL: (val * deal.CLIENT_UNIT_PRICE) / 100 }))
                                        }} style={MainInputStyle}
                                            colors={colors} theme={theme} />
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className='w-full  flex flex-col gap-1 my-2'>
                                    <label htmlFor="LVL1PER">النسبه المؤيه (%)</label>
                                    <div>
                                        <CustomTextFieldFullHeight disabled={isView ?? false} value={textFieldData('LE1_PERCENTAGE') ?? ""} height={25} id='LVL1PER' type={''} onChange={(e: any) => {
                                            let val = e.target.value;
                                            setCommission(prev => ({ ...prev, LE1_PERCENTAGE: val, LE1_TOTAL: (val * deal.CLIENT_UNIT_PRICE) / 100 }))

                                        }} style={MainInputStyle}
                                            colors={colors} theme={theme} />
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className='w-full  flex flex-col gap-1 my-2'>
                                    <label htmlFor="LVL2PER">النسبه المؤيه (%)</label>
                                    <div>
                                        <CustomTextFieldFullHeight disabled={isView ?? false} value={textFieldData('LE2_PERCENTAGE') ?? ""} height={25} id='LVL2PER' type={''} onChange={(e: any) => {
                                            let val = e.target.value;
                                            setCommission(prev => ({ ...prev, LE2_PERCENTAGE: val, LE2_TOTAL: (val * deal.CLIENT_UNIT_PRICE) / 100 }))
                                        }} style={MainInputStyle}
                                            colors={colors} theme={theme} />
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className='w-full  flex flex-col gap-1 my-2'>
                                    <label htmlFor="LVL3PER">النسبه المؤيه (%)</label>
                                    <div>
                                        <CustomTextFieldFullHeight disabled={isView ?? false} value={textFieldData('LE3_PERCENTAGE') ?? ""} height={25} id='LVL3PER' type={''} onChange={(e: any) => {
                                            let val = e.target.value;
                                            setCommission(prev => ({ ...prev, LE3_PERCENTAGE: val, LE3_TOTAL: (val * deal.CLIENT_UNIT_PRICE) / 100 }));
                                        }} style={MainInputStyle}
                                            colors={colors} theme={theme} />
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className='w-full  flex flex-col gap-1 my-2'>
                                    <label htmlFor="LVL4PER">النسبه المؤيه (%)</label>
                                    <div>
                                        <CustomTextFieldFullHeight disabled={isView ?? false} value={textFieldData('LE4_PERCENTAGE') ?? ""} height={25} id='LVL4PER' type={''} onChange={(e: any) => {
                                            let val = e.target.value;
                                            setCommission(prev => ({ ...prev, LE4_PERCENTAGE: val, LE4_TOTAL: (val * deal.CLIENT_UNIT_PRICE) / 100 }));
                                        }} style={MainInputStyle}
                                            colors={colors} theme={theme} />
                                    </div>
                                </div>
                            </td>
                            {/* manager */}
                            <td>
                                <div className='w-full  flex flex-col gap-1 my-2'>
                                    <label htmlFor="LVL4PER">النسبه المؤيه (%)</label>
                                    <div>
                                        <CustomTextFieldFullHeight disabled={isView ?? false} value={textFieldData('MANGER_PERCENTAGE') ?? ""} height={25} id='MANG_PREC' type={''} onChange={(e: any) => {
                                            let val = e.target.value;
                                            setCommission(prev => ({ ...prev, MANGER_PERCENTAGE: val, MANGER_TOTAL: (val * deal.CLIENT_UNIT_PRICE) / 100 }));
                                        }} style={MainInputStyle}
                                            colors={colors} theme={theme} />
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className='w-full  flex flex-col gap-1 my-2'>
                                    <label htmlFor="THE_TEAM_PER">النسبه المؤيه (%)</label>
                                    <div>
                                        <CustomTextFieldFullHeight disabled={isView ?? false} value={textFieldData('THE_TEAM_PERCENTAGE') ?? ""} height={25} id='THE_TEAM_PER' type={''} onChange={(e: any) => {
                                            let val = e.target.value;
                                            setCommission(prev => ({ ...prev, THE_TEAM_PERCENTAGE: val, THE_TEAM_TOTAL: (val * deal.CLIENT_UNIT_PRICE) / 100 }));
                                        }} style={MainInputStyle}
                                            colors={colors} theme={theme} />
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className='w-full  flex flex-col gap-1 my-2'>
                                    <label htmlFor="FOUNDER_PER">النسبه المؤيه (%)</label>
                                    <div>
                                        <CustomTextFieldFullHeight disabled={isView ?? false} value={textFieldData('FOUNDER_PERCENTAGE') ?? ""} height={25} id='FOUNDER_PER' type={''} onChange={(e: any) => {
                                            let val = e.target.value;
                                            setCommission(prev => ({ ...prev, FOUNDER_PERCENTAGE: val, FOUNDER_TOTAL: (val * deal.CLIENT_UNIT_PRICE) / 100 }));
                                        }} style={MainInputStyle}
                                            colors={colors} theme={theme} />
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className='w-full  flex flex-col gap-1 my-2'>
                                    <label htmlFor="BOX_PER">النسبه المؤيه (%)</label>
                                    <div>
                                        <CustomTextFieldFullHeight disabled={isView ?? false} value={textFieldData('BOX_PERCENTAGE') ?? ""} height={25} id='BOX_PER' type={''} onChange={(e: any) => {
                                            let val = e.target.value;
                                            setCommission(prev => ({ ...prev, BOX_PERCENTAGE: val, BOX_TOTAL: (val * deal.CLIENT_UNIT_PRICE) / 100 }));
                                        }} style={MainInputStyle}
                                            colors={colors} theme={theme} />
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className='w-full  flex flex-col gap-1 my-2'>
                                    <label htmlFor="COMPANY_PER">النسبه المؤيه (%)</label>
                                    <div>
                                        <CustomTextFieldFullHeight disabled={isView ?? false} value={textFieldData('COMPANY_PERCENTAGE') ?? ""} height={25} id='COMPANY_PER' type={''} onChange={(e: any) => {
                                            let val = e.target.value;
                                            setCommission(prev => ({ ...prev, COMPANY_PERCENTAGE: val, COMPANY_TOTAL: (val * deal.CLIENT_UNIT_PRICE) / 100 }));
                                        }} style={MainInputStyle}
                                            colors={colors} theme={theme} />
                                    </div>
                                </div>
                            </td>
                            {displayCommDiff && (
                                <td>
                                    <div className='w-full  flex flex-col gap-1 my-2'>
                                        <label htmlFor="extra_comm1">النسبه المؤيه (%)</label>
                                        <div>
                                            <CustomTextFieldFullHeight disabled={isView ?? false} value={textFieldData('EXTERNAL_COMMISSIONN_1_PRECENTAGE') ?? ""} height={25} id='extra_comm1' type={''} onChange={(e: any) => {
                                                let val = e.target.value;
                                                setCommission(prev => ({ ...prev, EXTERNAL_COMMISSIONN_1_PRECENTAGE: val, EXTERNAL_COMMISSIONN_1_TOTAL: (val * deal.CLIENT_UNIT_PRICE) / 100 }));
                                            }} style={MainInputStyle}
                                                colors={colors} theme={theme} />
                                        </div>
                                    </div>
                                </td>
                            )}
                            {displayCommDiff && (
                                <td>
                                    <div className='w-full  flex flex-col gap-1 my-2'>
                                        <label htmlFor="extra_comm2">النسبه المؤيه (%)</label>
                                        <div>
                                            <CustomTextFieldFullHeight disabled={isView ?? false} value={textFieldData('EXTERNAL_COMMISSIONN_2_PRECENTAGE') ?? ""} height={25} id='extra_comm2' type={''} onChange={(e: any) => {
                                                let val = e.target.value;
                                                setCommission(prev => ({ ...prev, EXTERNAL_COMMISSIONN_2_PRECENTAGE: val, EXTERNAL_COMMISSIONN_2_TOTAL: (val * deal.CLIENT_UNIT_PRICE) / 100 }));
                                            }} style={MainInputStyle}
                                                colors={colors} theme={theme} />
                                        </div>
                                    </div>
                                </td>
                            )}
                        </tr>
                        {/* tax percentage */}
                        <tr>
                            <td>
                                {/* <div className='w-full  flex flex-col gap-1 my-2'>
                                    <label htmlFor="LT">نسبة الضرائب</label>
                                    <div>
                                        <CustomTextFieldFullHeight disabled={isView ?? false} value={deal.CLIENT_UNIT_PRICE} height={25} id='LT' type={''} onChange={(e: any) => {
                                            // let val = e.target.value;
                                            // let isNumber = !isNaN(val)
                                            // if (!isNumber) {
                                            //     dispatch(AddAction({ message: "يجب ادخال رقم فقط", status: "error" }))
                                            // }



                                        }} style={MainInputStyle}
                                            colors={colors} theme={theme} />
                                    </div>
                                </div> */}
                            </td>
                            <td>
                                <div className='w-full  flex flex-col gap-1 my-2'>
                                    <label htmlFor="directTax">إجمالي الضرائب</label>
                                    <div>
                                        {/* <CustomTextFieldFullHeight disabled={isView ?? false} value={textFieldData('DIRECT_TAX_PREC')} height={25} id='directTax' type={''} onChange={(e: any) => {
                                            const value = parseFloat(e.target.value);
                                            if (!isNaN(value)) {
                                                UPDATE_COMMISSION('DIRECT_TAX_PREC', value);
                                            } else {
                                                UPDATE_COMMISSION('DIRECT_TAX_PREC', 0);
                                            }
                                        }} style={MainInputStyle}
                                            colors={colors} theme={theme} /> */}
                                        <InputCustom disabled={isView ?? false} DefaultValue={textFieldData('DIRECT_TAX_PREC') as string ?? ""} isNumber inputChangedVal={val => {
                                            const value = parseFloat(val.toString());
                                            if (!isNaN(value)) {
                                                UPDATE_COMMISSION('DIRECT_TAX_PREC', value);
                                            } else {
                                                UPDATE_COMMISSION('DIRECT_TAX_PREC', 0);
                                            }
                                        }} />
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className='w-full  flex flex-col gap-1 my-2'>
                                    <label htmlFor="LVL1_TAX">إجمالي الضرائب</label>
                                    <div>
                                        {/* <CustomTextFieldFullHeight disabled={isView ?? false} value={textFieldData('LVL1_TAX_PREC')} height={25} id='LVL1_TAX' type={''} onChange={(e: any) => {
                                            const value = parseFloat(e.target.value);
                                            if (!isNaN(value)) {
                                                UPDATE_COMMISSION('LVL1_TAX_PREC', value);
                                            } else {
                                                UPDATE_COMMISSION('LVL1_TAX_PREC', 0);
                                            }
                                        }} style={MainInputStyle}
                                            colors={colors} theme={theme} /> */}
                                        <InputCustom disabled={isView ?? false} DefaultValue={textFieldData('LVL1_TAX_PREC') as string ?? ""} isNumber inputChangedVal={val => {
                                            const value = parseFloat(val.toString());
                                            if (!isNaN(value)) {
                                                UPDATE_COMMISSION('LVL1_TAX_PREC', value);
                                            } else {
                                                UPDATE_COMMISSION('LVL1_TAX_PREC', 0);
                                            }
                                        }} />
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className='w-full  flex flex-col gap-1 my-2'>
                                    <label htmlFor="LVL2_TAX">إجمالي الضرائب</label>
                                    <div>
                                        {/* <CustomTextFieldFullHeight disabled={isView ?? false} value={textFieldData('LVL2_TAX_PREC')} height={25} id='LVL2_TAX' type={''} onChange={(e: any) => {
                                            const value = parseFloat(e.target.value);
                                            if (!isNaN(value)) {
                                                UPDATE_COMMISSION('LVL2_TAX_PREC', value);
                                            } else {
                                                UPDATE_COMMISSION('LVL2_TAX_PREC', 0);
                                            }
                                        }} style={MainInputStyle}
                                            colors={colors} theme={theme} /> */}

                                        <InputCustom disabled={isView ?? false} DefaultValue={textFieldData('LVL2_TAX_PREC') as string ?? ""} isNumber inputChangedVal={val => {
                                            const value = parseFloat(val.toString());
                                            if (!isNaN(value)) {
                                                UPDATE_COMMISSION('LVL2_TAX_PREC', value);
                                            } else {
                                                UPDATE_COMMISSION('LVL2_TAX_PREC', 0);
                                            }
                                        }} />
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className='w-full  flex flex-col gap-1 my-2'>
                                    <label htmlFor="LVL3_TAX">إجمالي الضرائب</label>
                                    <div>
                                        {/* <CustomTextFieldFullHeight disabled={isView ?? false} value={textFieldData('LVL3_TAX_PREC')} height={25} id='LVL3_TAX' type={''} onChange={(e: any) => {
                                            const value = parseFloat(e.target.value);
                                            if (!isNaN(value)) {
                                                UPDATE_COMMISSION('LVL3_TAX_PREC', value);
                                            } else {
                                                UPDATE_COMMISSION('LVL3_TAX_PREC', 0);
                                            }
                                        }} style={MainInputStyle}
                                            colors={colors} theme={theme} /> */}

                                        <InputCustom disabled={isView ?? false} DefaultValue={textFieldData('LVL3_TAX_PREC') as string ?? ""} isNumber inputChangedVal={val => {
                                            const value = parseFloat(val.toString());
                                            if (!isNaN(value)) {
                                                UPDATE_COMMISSION('LVL3_TAX_PREC', value);
                                            } else {
                                                UPDATE_COMMISSION('LVL3_TAX_PREC', 0);
                                            }
                                        }} />

                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className='w-full  flex flex-col gap-1 my-2'>
                                    <label htmlFor="LVL4_TAX">إجمالي الضرائب</label>
                                    <div>
                                        {/* <CustomTextFieldFullHeight disabled={isView ?? false} value={textFieldData('LVL4_TAX_PREC')} height={25} id='LVL4_TAX' type={''} onChange={(e: any) => {
                                            const value = parseFloat(e.target.value);
                                            if (!isNaN(value)) {
                                                UPDATE_COMMISSION('LVL4_TAX_PREC', value);
                                            } else {
                                                UPDATE_COMMISSION('LVL4_TAX_PREC', 0);
                                            }
                                        }} style={MainInputStyle}
                                            colors={colors} theme={theme} /> */}

                                        <InputCustom disabled={isView ?? false} DefaultValue={textFieldData('LVL4_TAX_PREC') as string ?? ""} isNumber inputChangedVal={val => {
                                            const value = parseFloat(val.toString());
                                            if (!isNaN(value)) {
                                                UPDATE_COMMISSION('LVL4_TAX_PREC', value);
                                            } else {
                                                UPDATE_COMMISSION('LVL4_TAX_PREC', 0);
                                            }
                                        }} />
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className='w-full  flex flex-col gap-1 my-2'>
                                    <label htmlFor="MANG_TAX">إجمالي الضرائب</label>
                                    <div>
                                        {/* <CustomTextFieldFullHeight disabled={isView ?? false} value={textFieldData('LVL4_TAX_PREC')} height={25} id='LVL4_TAX' type={''} onChange={(e: any) => {
                                            const value = parseFloat(e.target.value);
                                            if (!isNaN(value)) {
                                                UPDATE_COMMISSION('LVL4_TAX_PREC', value);
                                            } else {
                                                UPDATE_COMMISSION('LVL4_TAX_PREC', 0);
                                            }
                                        }} style={MainInputStyle}
                                            colors={colors} theme={theme} /> */}

                                        <InputCustom disabled={isView ?? false} DefaultValue={textFieldData('MANGER_TAX_PREC') as string ?? ""} isNumber inputChangedVal={val => {
                                            const value = parseFloat(val.toString());
                                            if (!isNaN(value)) {
                                                UPDATE_COMMISSION('MANGER_TAX_PREC', value);
                                            } else {
                                                UPDATE_COMMISSION('MANGER_TAX_PREC', 0);
                                            }
                                        }} />
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className='w-full  flex flex-col gap-1 my-2'>
                                    <label htmlFor="TEAM_TAX">إجمالي الضرائب</label>
                                    <div>
                                        {/* <CustomTextFieldFullHeight disabled={isView ?? false} value={textFieldData('THE_TEAM_TAX_PREC')} height={25} id='TEAM_TAX' type={''} onChange={(e: any) => {
                                            const value = parseFloat(e.target.value);
                                            if (!isNaN(value)) {
                                                UPDATE_COMMISSION('THE_TEAM_TAX_PREC', value);
                                            } else {
                                                UPDATE_COMMISSION('THE_TEAM_TAX_PREC', 0);
                                            }

                                        }} style={MainInputStyle}
                                            colors={colors} theme={theme} /> */}
                                        <InputCustom disabled={isView ?? false} DefaultValue={textFieldData('THE_TEAM_TAX_PREC') as string ?? ""} isNumber inputChangedVal={val => {
                                            const value = parseFloat(val.toString());
                                            if (!isNaN(value)) {
                                                UPDATE_COMMISSION('THE_TEAM_TAX_PREC', value);
                                            } else {
                                                UPDATE_COMMISSION('THE_TEAM_TAX_PREC', 0);
                                            }
                                        }} />
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className='w-full  flex flex-col gap-1 my-2'>
                                    <label htmlFor="BOX_TAX">إجمالي الضرائب</label>
                                    <div>
                                        {/* <CustomTextFieldFullHeight disabled={isView ?? false} value={textFieldData('BOX_TAX_PREC')} height={25} id='BOX_TAX' type={''} onChange={(e: any) => {
                                            const value = parseFloat(e.target.value);
                                            if (!isNaN(value)) {
                                                UPDATE_COMMISSION('BOX_TAX_PREC', value);
                                            } else {
                                                UPDATE_COMMISSION('BOX_TAX_PREC', 0);
                                            }
                                        }} style={MainInputStyle}
                                            colors={colors} theme={theme} /> */}
                                        <InputCustom disabled={isView ?? false} DefaultValue={textFieldData('BOX_TAX_PREC') as string ?? ""} isNumber inputChangedVal={val => {
                                            const value = parseFloat(val.toString());
                                            if (!isNaN(value)) {
                                                UPDATE_COMMISSION('BOX_TAX_PREC', value);
                                            } else {
                                                UPDATE_COMMISSION('BOX_TAX_PREC', 0);
                                            }
                                        }} />
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className='w-full  flex flex-col gap-1 my-2'>
                                    <label htmlFor="COMP_TAX">إجمالي الضرائب</label>
                                    <div>
                                        {/* <CustomTextFieldFullHeight disabled={isView ?? false} value={textFieldData('COMPANY_TAX_PREC')} height={25} id='COMP_TAX' type={''} onChange={(e: any) => {
                                            const value = parseFloat(e.target.value);
                                            if (!isNaN(value)) {
                                                UPDATE_COMMISSION('COMPANY_TAX_PREC', value);
                                            } else {
                                                UPDATE_COMMISSION('COMPANY_TAX_PREC', 0);
                                            }
                                        }} style={MainInputStyle}
                                            colors={colors} theme={theme} /> */}
                                        <InputCustom disabled={isView ?? false} DefaultValue={textFieldData('COMPANY_TAX_PREC') as string ?? ""} isNumber inputChangedVal={val => {
                                            const value = parseFloat(val.toString());
                                            if (!isNaN(value)) {
                                                UPDATE_COMMISSION('COMPANY_TAX_PREC', value);
                                            } else {
                                                UPDATE_COMMISSION('COMPANY_TAX_PREC', 0);
                                            }
                                        }} />
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className='w-full  flex flex-col gap-1 my-2'>
                                    <label htmlFor="FOUNDER_TAX">إجمالي الضرائب</label>
                                    <div>
                                        {/* <CustomTextFieldFullHeight disabled={isView ?? false} value={textFieldData('FOUNDER_TAX_PREC')} height={25} id='FOUNDER_TAX' type={''} onChange={(e: any) => {
                                            const value = parseFloat(e.target.value);
                                            if (!isNaN(value)) {
                                                UPDATE_COMMISSION('FOUNDER_TAX_PREC', value);
                                            } else {
                                                UPDATE_COMMISSION('FOUNDER_TAX_PREC', 0);
                                            }
                                        }} style={MainInputStyle}
                                            colors={colors} theme={theme} /> */}
                                        <InputCustom disabled={isView ?? false} DefaultValue={textFieldData('FOUNDER_TAX_PREC') as string ?? ""} isNumber inputChangedVal={val => {
                                            const value = parseFloat(val.toString());
                                            if (!isNaN(value)) {
                                                UPDATE_COMMISSION('FOUNDER_TAX_PREC', value);
                                            } else {
                                                UPDATE_COMMISSION('FOUNDER_TAX_PREC', 0);
                                            }
                                        }} />
                                    </div>
                                </div>
                            </td>
                        </tr>
                        {/* total */}
                        <tr>
                            <td>
                                <div className='w-full  flex flex-col gap-1 my-2'>
                                    <label htmlFor="LT">سعر الوحده</label>
                                    <div>
                                        <CustomTextFieldFullHeight disabled={isView ?? false} value={deal.CLIENT_UNIT_PRICE} height={25} id='LT' type={''} onChange={(e: any) => {
                                            // let val = e.target.value;
                                            // let isNumber = !isNaN(val)
                                            // if (!isNumber) {
                                            //     dispatch(AddAction({ message: "يجب ادخال رقم فقط", status: "error" }))
                                            // }



                                        }} style={MainInputStyle}
                                            colors={colors} theme={theme} />
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className='w-full  flex flex-col gap-1 my-2'>
                                    <label htmlFor="DIRECT_TOTAL">الإجمالي</label>
                                    <div>
                                        {/* <CustomTextFieldFullHeight disabled={isView ?? false} value={textFieldData('DIRECT_TOTAL')} height={25} id='DIRECT_TOTAL' type={''} onChange={(e: any) => { */}
                                        <CustomTextFieldFullHeight disabled={isView ?? false} value={isView === true ? deal.DEAL_COMMISION?.DIRECT_TOTAL ?? 0 : commission.DIRECT_TOTAL} height={25} id='DIRECT_TOTAL' type={''} onChange={(e: any) => {
                                            // let val = e.target.value;
                                            // let isNumber = !isNaN(val)
                                            // if (!isNumber) {
                                            //     dispatch(AddAction({ message: "يجب ادخال رقم فقط", status: "error" }))
                                            // }

                                            // UPDATE_COMMISSION('DIRECT_TOTAL', parseFloat(val));

                                        }} style={MainInputStyle}
                                            colors={colors} theme={theme} />
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className='w-full  flex flex-col gap-1 my-2'>
                                    <label htmlFor="LVL1_TOTAL">الإجمالي</label>
                                    <div>
                                        <CustomTextFieldFullHeight disabled={isView ?? false} value={isView === true ? deal.DEAL_COMMISION?.LE1_TOTAL ?? 0 : commission.LE1_TOTAL} height={25} id='LVL1_TOTAL' type={''} onChange={(e: any) => {
                                            // <CustomTextFieldFullHeight disabled={isView ?? false} value={textFieldData('LE1_TOTAL')} height={25} id='LVL1_TOTAL' type={''} onChange={(e: any) => {
                                            // let val = e.target.value;
                                            // let isNumber = !isNaN(val)
                                            // if (!isNumber) {
                                            //     dispatch(AddAction({ message: "يجب ادخال رقم فقط", status: "error" }))
                                            // }

                                            // UPDATE_COMMISSION('LE1_TOTAL', parseFloat(val));
                                        }} style={MainInputStyle}
                                            colors={colors} theme={theme} />
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className='w-full  flex flex-col gap-1 my-2'>
                                    <label htmlFor="LVL2_TOTAL">الإجمالي</label>
                                    <div>
                                        <CustomTextFieldFullHeight disabled={isView ?? false} value={isView === true ? deal.DEAL_COMMISION?.LE2_TOTAL ?? 0 : commission.LE2_TOTAL} height={25} id='LVL2_TOTAL' type={''} onChange={(e: any) => {
                                            // <CustomTextFieldFullHeight disabled={isView ?? false} value={textFieldData('LE2_TOTAL')} height={25} id='LVL2_TOTAL' type={''} onChange={(e: any) => {
                                            // let val = e.target.value;
                                            // let isNumber = !isNaN(val)
                                            // if (!isNumber) {
                                            //     dispatch(AddAction({ message: "يجب ادخال رقم فقط", status: "error" }))
                                            // }

                                            // UPDATE_COMMISSION('LE2_TOTAL', parseFloat(val));

                                        }} style={MainInputStyle}
                                            colors={colors} theme={theme} />
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className='w-full  flex flex-col gap-1 my-2'>
                                    <label htmlFor="LVL3_TOTAL">الإجمالي</label>
                                    <div>
                                        <CustomTextFieldFullHeight disabled={isView ?? false} value={isView === true ? deal.DEAL_COMMISION?.LE3_TOTAL ?? 0 : commission.LE3_TOTAL} height={25} id='LVL3_TOTAL' type={''} onChange={(e: any) => {
                                            // <CustomTextFieldFullHeight disabled={isView ?? false} value={textFieldData('LE3_TOTAL')} height={25} id='LVL3_TOTAL' type={''} onChange={(e: any) => {
                                            // let val = e.target.value;
                                            // let isNumber = !isNaN(val)
                                            // if (!isNumber) {
                                            //     dispatch(AddAction({ message: "يجب ادخال رقم فقط", status: "error" }))
                                            // }

                                            // UPDATE_COMMISSION('LE3_TOTAL', parseFloat(val));


                                        }} style={MainInputStyle}
                                            colors={colors} theme={theme} />
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className='w-full  flex flex-col gap-1 my-2'>
                                    <label htmlFor="LVL4_TOTAL">الإجمالي</label>
                                    <div>
                                        <CustomTextFieldFullHeight disabled={isView ?? false} value={isView === true ? deal.DEAL_COMMISION?.LE4_TOTAL ?? 0 : commission.LE4_TOTAL} height={25} id='LVL4_TOTAL' type={''} onChange={(e: any) => {
                                            // <CustomTextFieldFullHeight disabled={isView ?? false} value={textFieldData('LE4_TOTAL')} height={25} id='LVL4_TOTAL' type={''} onChange={(e: any) => {
                                            // let val = e.target.value;
                                            // let isNumber = !isNaN(val)
                                            // if (!isNumber) {
                                            //     dispatch(AddAction({ message: "يجب ادخال رقم فقط", status: "error" }))
                                            // }

                                            // UPDATE_COMMISSION('LE4_TOTAL', parseFloat(val));

                                        }} style={MainInputStyle}
                                            colors={colors} theme={theme} />
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className='w-full  flex flex-col gap-1 my-2'>
                                    <label htmlFor="MANG_TOT">الإجمالي</label>
                                    <div>
                                        <CustomTextFieldFullHeight disabled={isView ?? false} value={isView === true ? deal.DEAL_COMMISION?.MANGER_TOTAL ?? 0 : commission.MANGER_TOTAL} height={25} id='MANGER_TOTAL' type={''} onChange={(e: any) => {
                                            // <CustomTextFieldFullHeight disabled={isView ?? false} value={textFieldData('LE4_TOTAL')} height={25} id='LVL4_TOTAL' type={''} onChange={(e: any) => {
                                            // let val = e.target.value;
                                            // let isNumber = !isNaN(val)
                                            // if (!isNumber) {
                                            //     dispatch(AddAction({ message: "يجب ادخال رقم فقط", status: "error" }))
                                            // }

                                            // UPDATE_COMMISSION('LE4_TOTAL', parseFloat(val));

                                        }} style={MainInputStyle}
                                            colors={colors} theme={theme} />
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className='w-full  flex flex-col gap-1 my-2'>
                                    <label htmlFor="THE_TEAM_TOTAL">الإجمالي</label>
                                    <div>
                                        {/* <CustomTextFieldFullHeight disabled={isView ?? false} value={textFieldData('THE_TEAM_TOTAL')} height={25} id='THE_TEAM_TOTAL' type={''} onChange={(e: any) => { */}
                                        <CustomTextFieldFullHeight disabled={isView ?? false} value={isView === true ? deal.DEAL_COMMISION?.THE_TEAM_TOTAL ?? 0 : commission.THE_TEAM_TOTAL} height={25} id='THE_TEAM_TOTAL' type={''} onChange={(e: any) => {
                                            // let val = e.target.value;
                                            // let isNumber = !isNaN(val)
                                            // if (!isNumber) {
                                            //     dispatch(AddAction({ message: "يجب ادخال رقم فقط", status: "error" }))
                                            // }

                                            // UPDATE_COMMISSION('THE_TEAM_TOTAL', parseFloat(val));

                                        }} style={MainInputStyle}
                                            colors={colors} theme={theme} />
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className='w-full  flex flex-col gap-1 my-2'>
                                    <label htmlFor="FOUNDER_TOTAL">الإجمالي</label>
                                    <div>
                                        {/* <CustomTextFieldFullHeight disabled={isView ?? false} value={textFieldData('FOUNDER_TOTAL')} height={25} id='FOUNDER_TOTAL' type={''} onChange={(e: any) => { */}
                                        <CustomTextFieldFullHeight disabled={isView ?? false} value={isView === true ? deal.DEAL_COMMISION?.FOUNDER_TOTAL ?? 0 : commission.FOUNDER_TOTAL} height={25} id='FOUNDER_TOTAL' type={''} onChange={(e: any) => {
                                            // let val = e.target.value;
                                            // let isNumber = !isNaN(val)
                                            // if (!isNumber) {
                                            //     dispatch(AddAction({ message: "يجب ادخال رقم فقط", status: "error" }))
                                            // }

                                            // UPDATE_COMMISSION('FOUNDER_TOTAL', parseFloat(val));

                                        }} style={MainInputStyle}
                                            colors={colors} theme={theme} />
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className='w-full  flex flex-col gap-1 my-2'>
                                    <label htmlFor="BOX_TOTAL">الإجمالي</label>
                                    <div>
                                        <CustomTextFieldFullHeight disabled={isView ?? false} value={isView === true ? deal.DEAL_COMMISION?.BOX_TOTAL ?? 0 : commission.BOX_TOTAL} height={25} id='T8' type={''} onChange={(e: any) => {
                                            // let val = e.target.value;
                                            // let isNumber = !isNaN(val)
                                            // if (!isNumber) {
                                            //     dispatch(AddAction({ message: "يجب ادخال رقم فقط", status: "error" }))
                                            // }

                                            // UPDATE_COMMISSION('BOX_TOTAL', parseFloat(val));

                                        }} style={MainInputStyle}
                                            colors={colors} theme={theme} />
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className='w-full  flex flex-col gap-1 my-2'>
                                    <label htmlFor="COMPANY_TOTAL">الإجمالي</label>
                                    <div>
                                        <CustomTextFieldFullHeight disabled={isView ?? false} value={isView === true ? deal.DEAL_COMMISION?.COMPANY_TOTAL ?? 0 : commission.COMPANY_TOTAL} height={25} id='COMPANY_TOTAL' type={''} onChange={(e: any) => {
                                            // let val = e.target.value;
                                            // let isNumber = !isNaN(val)
                                            // if (!isNumber) {
                                            //     dispatch(AddAction({ message: "يجب ادخال رقم فقط", status: "error" }))
                                            // }
                                            // UPDATE_COMMISSION('COMPANY_TOTAL', parseFloat(val));
                                        }} style={MainInputStyle}
                                            colors={colors} theme={theme} />
                                    </div>
                                </div>
                            </td>
                            {displayCommDiff && (
                                <td>
                                    <div className='w-full  flex flex-col gap-1 my-2'>
                                        <label htmlFor="extra_comm1_total">الإجمالي</label>
                                        <div>
                                            <CustomTextFieldFullHeight disabled={isView ?? false} value={isView === true ? deal.DEAL_COMMISION?.EXTERNAL_COMMISSIONN_1_TOTAL ?? 0 : commission.EXTERNAL_COMMISSIONN_1_TOTAL} height={25} id='extra_comm1_total' type={''} onChange={(e: any) => {
                                                // let val = e.target.value;
                                                // let isNumber = !isNaN(val)
                                                // if (!isNumber) {
                                                //     dispatch(AddAction({ message: "يجب ادخال رقم فقط", status: "error" }))
                                                // }

                                                // UPDATE_COMMISSION('EXTERNAL_COMMISSIONN_1_TOTAL', parseFloat(val));

                                            }} style={MainInputStyle}
                                                colors={colors} theme={theme} />
                                        </div>
                                    </div>
                                </td>
                            )}
                            {displayCommDiff && (
                                <td>
                                    <div className='w-full  flex flex-col gap-1 my-2'>
                                        <label htmlFor="extra_comm2_total">الإجمالي</label>
                                        <div>
                                            <CustomTextFieldFullHeight disabled={isView ?? false} value={isView === true ? deal.DEAL_COMMISION?.EXTERNAL_COMMISSIONN_2_TOTAL ?? 0 : commission.EXTERNAL_COMMISSIONN_2_TOTAL} height={25} id='extra_comm2_total' type={''} onChange={(e: any) => {
                                                // let val = e.target.value;
                                                // let isNumber = !isNaN(val)
                                                // if (!isNumber) {
                                                //     dispatch(AddAction({ message: "يجب ادخال رقم فقط", status: "error" }))
                                                // }

                                                // UPDATE_COMMISSION('EXTERNAL_COMMISSIONN_2_TOTAL', parseFloat(val));

                                            }} style={MainInputStyle}
                                                colors={colors} theme={theme} />
                                        </div>
                                    </div>
                                </td>
                            )}
                        </tr>
                    </tbody>
                </table>
            </div>
            {(isView === undefined || isView === false) && (
                <div className='flex w-1/5 gap-4 mt-5 justify-between'>
                    <EgButton HandleClick={() => {
                        // 
                        SAVE_COMMISSION_API(commission)
                    }}>
                        <h5 className="text-base md:text-lg">حفظ</h5>
                    </EgButton>
                    {/* <EgButton HandleClick={() => {
                    
                }}>
                    <h3 className="text-base md:text-lg">طباعه</h3>
                </EgButton> */}
                </div>
            )}
            <div className='absolute top-4 left-4 i-material-symbols-close-rounded text-xl text-red-500 hover:cursor-pointer' onClick={() => {
                clearCommission()
                setCommissionDisplay(
                    {
                        ACTION_ID: deal.ACTION_ID,
                        // PROJECT_ID: undefined,
                        NAME_PERCENTAGE: 0,
                        NAME_TOTAL: 0,
                        NAME: deal.APPLICANT_NAME,
                        DIRECT_PERCENTAGE: 0,
                        DIRECT_TOTAL: 0,
                        DIRECT_NAME: deal.DIRECT_USER_NAME,
                        COMMISSION_DIFFERENCE_NAME: undefined,
                        COMMISSION_DIFFERENCE_PERCENTAGE: 0,
                        COMMISSION_DIFFERENCE_TOTAL: 0,
                        LE1_PERCENTAGE: 0,
                        LE1_TOTAL: 0,
                        LE2_PERCENTAGE: 0,
                        LE2_TOTAL: 0,
                        LE3_PERCENTAGE: 0,
                        LE3_TOTAL: 0,
                        LE4_PERCENTAGE: 0,
                        LE4_TOTAL: 0,
                        THE_TEAM_PERCENTAGE: 0,
                        THE_TEAM_TOTAL: 0,
                        BOX_PERCENTAGE: 0,
                        BOX_TOTAL: 0,
                        COMPANY_PERCENTAGE: 0,
                        COMPANY_TOTAL: 0,
                        FOUNDER_PERCENTAGE: 0,
                        FOUNDER_TOTAL: 0
                    } as COMMISSIONS
                )
            }}></div>
        </div>
    )
}

export default Percentage