import { useTheme } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CustomTextFieldFullHeight } from '../../../CustomComps/Customs';
import { Boxes, GetErrorAxios } from '../../../Globals';
import { GetAllUserDetail, trans_deals, trans_fin_save, trans_types } from '../../../Service/Apis';
import { MONEY_SOURCE, TRANSACTIONS, TRANSACTION_CATG, TRANSACTION_DETALS, TRANSACTION_TYPES, UserDeals } from '../../../Service/models/TransActions_model';
import { USER_Detail } from '../../../Service/models/UserModel/UserModel';
import { AddAction } from '../../../redux/slices/ErrorsSlice';
import { setLoadingFalse, setLoadingTrue } from '../../../redux/slices/LoadingSlice';
import { AppDispatch } from '../../../redux/store';
import { tokens } from '../../../theme';
import { DropDownData, DropTextField } from '../../DashBoard/Actions/ActionsTypesScreen/AppointMents';
import { EgButton } from '../../Shared/Shared';
import Equality from '../EqualityDeposit';
import './style.css'
import EGDate from '../../../components/shared/EGDate';

const Deposit = () => {

  //global data
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const MainInputStyle = { marginBottom: 2, width: '100%', backgroundColor: 'white' };
  const dispatch = useDispatch<AppDispatch>()

  //Request
  const [TransRequest, setTransRequest] = useState<TRANSACTIONS>({} as TRANSACTIONS)

  //change & total number
  const [changeData, setChangeData] = useState<{ EGP: string, FiveEGP: string, TenEgp: string, twentyEGP: string, fiftyEGP: string, hundredEGP: string, TwoHundredEGP: string }>({ EGP: "0", FiveEGP: "0", TenEgp: "0", twentyEGP: "0", fiftyEGP: "0", hundredEGP: "0", TwoHundredEGP: "0" })
  const [total, setTotal] = useState<string>("")

  //Users State
  const [users, setUsers] = useState<USER_Detail[]>([])
  const [selectedUserId, setSelectedUserId] = useState<number>(-1)
  const [userDropDown, setUserDropDown] = useState<DropDownData[]>([])

  //Box State
  const [selectedBoxId, setSelectedBoxId] = useState<number>(-1)

  //TransReason
  const [transReason, setTransUser] = useState<TRANSACTION_TYPES[]>([])
  const [selectedTransReason, setSelectedReason] = useState<number>(-1)
  const [TransReasonDropDown, setTransReasonDropDown] = useState<DropDownData[]>([])
  const [BoxesDropDown, setBoxesDropDown] = useState<DropDownData[]>([])

  // force withdraw 
  const [forceWithdraw, setForceWithdraw] = useState<boolean>(false)
  const [isBankTransfer, setIsBankTransfer] = useState<boolean>(false)


  // notes 
  const [notes, setNotes] = useState<string>("")


  // DealsPerUser
  const [userDeals, setUserDeals] = useState<UserDeals>({})

  // Selected Deals To withdraw from 
  const [selectedDeals, setSelectedDeals] = useState<TRANSACTION_DETALS[]>([])

  // Money Source State 
  const [moneySource, setMoneySource] = useState<MONEY_SOURCE>(MONEY_SOURCE.UNKNOWN)

  // deposit date 
  const [depDate, setDepDate] = useState<Date>(new Date())

  // Refs 
  const EqualityRef = useRef<HTMLDivElement>(null)


  //functions

  // get all required data for dropdowns in screen
  async function GetAllDropDownData() {
    let transTypes = await trans_types()
    let AllUsers = await GetAllUserDetail({ WithAdmin: false, WithNewUsers: false })


    // users dropdown logic 
    if (AllUsers.length > 0) {
      setUsers(AllUsers)
      let DropDownMap: DropDownData[] = AllUsers.map((item, idx) => ({ id: item.ID, desc: item.FULL_NAME }))
      setUserDropDown(DropDownMap)
    }

    //trans dropdown logic 
    if (transTypes !== null && transTypes.length > 0) {
      setTransUser(transTypes)
      let TransTypesDropDownMap: DropDownData[] = transTypes.map((item, idx) => ({ id: item.ID, desc: item.DESCRIPTION }))
      setTransReasonDropDown(TransTypesDropDownMap)
    }

  }

  // calculate the sum of all changes in screens
  function CalculateTotal(Amounts: { EGP: string, FiveEGP: string, TenEgp: string, twentyEGP: string, fiftyEGP: string, hundredEGP: string, TwoHundredEGP: string }) {
    if (Amounts.TwoHundredEGP === "") Amounts.TwoHundredEGP = "0"
    if (Amounts.hundredEGP === "") Amounts.hundredEGP = "0"
    if (Amounts.fiftyEGP === "") Amounts.fiftyEGP = "0"
    if (Amounts.twentyEGP === "") Amounts.twentyEGP = "0"
    if (Amounts.TenEgp === "") Amounts.TenEgp = "0"
    if (Amounts.FiveEGP === "") Amounts.FiveEGP = "0"
    if (Amounts.EGP === "") Amounts.EGP = "0"



    let EGPAmount = parseInt(Amounts.EGP) ?? 0
    let EGPFive_Amount = (parseInt(Amounts.FiveEGP) ?? 0) * 5
    let EGPTen_Amount = (parseInt(Amounts.TenEgp) ?? 0) * 10
    let EGPTwenty_Amount = (parseInt(Amounts.twentyEGP) ?? 0) * 20
    let EGPFifty_Amount = (parseInt(Amounts.fiftyEGP) ?? 0) * 50
    let EGPHundred_Amount = (parseInt(Amounts.hundredEGP) ?? 0) * 100
    let EGPTwoHundred_Amount = (parseInt(Amounts.TwoHundredEGP) ?? 0) * 200

    return EGPAmount + EGPFive_Amount + EGPTen_Amount + EGPTwenty_Amount + EGPFifty_Amount + EGPHundred_Amount + EGPTwoHundred_Amount
  }


  // Get deals per user
  async function GetUserDeals() {
    if (selectedUserId === -1) {
      dispatch(AddAction({ message: "رجاء اختيار مستخدم", status: 'error' }))
    } else {
      let allDeals = await trans_deals(selectedUserId, true);

      if (allDeals != null) {
        setUserDeals(allDeals)
      }
    }
  }

  // hide & display Equality components
  const hideEquality = () => {
    EqualityRef.current?.classList.add('hideEquality')
    EqualityRef.current?.classList.remove('showEquality')
  }
  const showEquality = () => {
    EqualityRef.current?.classList.remove('hideEquality')
    EqualityRef.current?.classList.add('showEquality')
  }






  // useEffect Logic
  useEffect(() => {
    GetAllDropDownData()
    hideEquality()
  }, [])

  useEffect(() => {

    if (moneySource == MONEY_SOURCE.BOX_SOURCE) {
      let TransTypesDropDownMap: DropDownData[] = transReason.filter(item => item.IS_BOX === true).map((item, idx) => ({ id: item.ID, desc: item.DESCRIPTION }))
      setTransReasonDropDown(TransTypesDropDownMap)
    }
    if (moneySource == MONEY_SOURCE.OTHER_SOURCE) {
      let TransTypesDropDownMap: DropDownData[] = transReason.filter(item => item.IS_OTHER === true).map((item, idx) => ({ id: item.ID, desc: item.DESCRIPTION }))
      setTransReasonDropDown(TransTypesDropDownMap)
    }

    if (moneySource == MONEY_SOURCE.DEAL_SOURCE) {
      let TransTypesDropDownMap: DropDownData[] = transReason.filter(item => item.IS_DEAL === true).map((item, idx) => ({ id: item.ID, desc: item.DESCRIPTION }))
      setTransReasonDropDown(TransTypesDropDownMap)
      setBoxesDropDown([{ id: Boxes.CommissionBox, desc: "عمولات" }])
    }


    if (moneySource == MONEY_SOURCE.OTHER_SOURCE) {
      let TransTypesDropDownMap: DropDownData[] = transReason.map((item, idx) => ({ id: item.ID, desc: item.DESCRIPTION }))
      setTransReasonDropDown(TransTypesDropDownMap)
      setBoxesDropDown([
        { id: Boxes.CommissionBox, desc: "عمولات" },
        { id: Boxes.CompanyBox, desc: "الشركه" },
        { id: Boxes.ExpensesBox, desc: "المصاريف" },
        { id: Boxes.FoundersBox, desc: "المؤسسين" },
        { id: Boxes.TaxBox, desc: "الضرائب" },
        { id: Boxes.TheTeamBox, desc: "TheTeam" },
        { id: Boxes.ZakahBox, desc: "الزكاه" },])
    }


  }, [moneySource])

  useEffect(() => {

  }, [selectedDeals])


  useEffect(() => {
    let totalSum = CalculateTotal(changeData)
    setTotal(totalSum.toString())
  }, [changeData])



  // Main Function Request 
  const DepositMoney = (ignoreEquality: boolean = false) => {
    // No change Added
    if (total === undefined || total === "" || total === "0") {
      dispatch(AddAction({ message: "برجاء ادخال قيم للإيداع", status: 'error' }))
      return;
    }


    // // no source selected 
    // if (moneySource === MONEY_SOURCE.UNKNOWN) {
    //   dispatch(AddAction({ message: "برجاء اختيار سبب الإيداع", status: 'error' }))
    //   return;
    // }

    // // no box selected 
    // if (selectedBoxId === -1) {
    //   dispatch(AddAction({ message: "برجاء إختيار الصندوق", status: 'error' }))
    //   return;
    // }




    let totalConversion = total
    if (total === "") totalConversion = "0"

    let TransDetails: TRANSACTION_DETALS[] = []
    if (selectedBoxId !== Boxes.CommissionBox && moneySource !== MONEY_SOURCE.DEAL_SOURCE) {
      TransDetails.push({
        AMOUNT: parseFloat(totalConversion),
        DESTINATION_ID: selectedBoxId,
        BENEFICIARY_USER_ID: selectedUserId
      })
    } else {
      TransDetails = selectedDeals
    }

    let withdrawRequestObject: TRANSACTIONS = {
      AMOUNT: parseFloat(totalConversion),
      COUNT_1: parseInt(changeData.EGP),
      COUNT_10: parseInt(changeData.TenEgp),
      COUNT_5: parseInt(changeData.FiveEGP),
      COUNT_20: parseInt(changeData.twentyEGP),
      COUNT_50: parseInt(changeData.fiftyEGP),
      COUNT_100: parseInt(changeData.hundredEGP),
      COUNT_200: parseInt(changeData.TwoHundredEGP),
      SOURCE_TYPE: moneySource,
      TRANSACTION_CATG: TRANSACTION_CATG.DEBIT_CATG,
      NOTES: notes,
      TRANSACTION_DETALS: TransDetails,
      FORCE_TRANSACTION: forceWithdraw,
      TRANSACTION_TYPE_ID: selectedTransReason,
      EFFECT_DATE: depDate
    }


    // check if entered amount not equal selected deal amount 
    if (ignoreEquality === false) {
      if (withdrawRequestObject.AMOUNT ?? 0 !== selectedDeals[0].AMOUNT) {
        showEquality()
        return;
      }
    }
    // if no deals selected and box type is commission
    // if no change is selected 



    dispatch(setLoadingTrue())
    trans_fin_save(withdrawRequestObject).then(res => {
      dispatch(AddAction({ message: "تم الإيداع بنجاح", status: 'success' }))
      window.location.reload()
    }).catch((err) => {
      GetErrorAxios(err, dispatch)
    }).finally(() => {
      dispatch(setLoadingFalse())
    })

  }

  return (
    // grid container
    <div className='grid grid-cols-2 grid-rows-2 gap-3 relative'>
      {/* right top side */}
      <div className='bg-white rounded shadow col-start-1 col-end-2 row-start-1 row-end-2'>
        {/* main container */}
        <div className='w-full h-full p-3'>
          <h3 className='mb-5 font-bold'>الفئات</h3>
          {/* فئات الفلوس */}
          <div className='flex justify-between flex-wrap w-full mb-8'>
            {/* الجنيه */}
            <div className='max-w-[75px]'>
              <h6 className='mb-2'>الجنيه</h6>
              <CustomTextFieldFullHeight height={25} id='EGP' type={''} onChange={(e: any) => {
                let val = e.target.value as string;
                setChangeData(prev => ({ ...prev, EGP: val }))

              }} style={MainInputStyle}
                colors={colors} theme={theme} />
            </div>
            {/* 5 جنيه */}
            <div className='max-w-[75px]'>
              <h6 className='mb-2'>5 جنيه</h6>
              <CustomTextFieldFullHeight height={25} id='FiveEGP' type={''} onChange={(e: any) => {
                let val = e.target.value as string;
                setChangeData(prev => ({ ...prev, FiveEGP: val }))

              }} style={MainInputStyle}
                colors={colors} theme={theme} />
            </div>
            {/* 10 جنيه */}
            <div className='max-w-[75px]'>
              <h6 className='mb-2'>10 جنيه</h6>
              <CustomTextFieldFullHeight height={25} id='TenEGP' type={''} onChange={(e: any) => {
                let val = e.target.value as string;
                setChangeData(prev => ({ ...prev, TenEgp: val }))

              }} style={MainInputStyle}
                colors={colors} theme={theme} />
            </div>
            {/* 20 جنيه */}
            <div className='max-w-[75px]'>
              <h6 className='mb-2'>20 جنيه</h6>
              <CustomTextFieldFullHeight height={25} id='twentyEGP' type={''} onChange={(e: any) => {
                let val = e.target.value as string;
                setChangeData(prev => ({ ...prev, twentyEGP: val }))

              }} style={MainInputStyle}
                colors={colors} theme={theme} />
            </div>
            {/* 50 جنيه */}
            <div className='max-w-[75px]'>
              <h6 className='mb-2'>50 جنيه</h6>
              <CustomTextFieldFullHeight height={25} id='FiftyEGP' type={''} onChange={(e: any) => {
                let val = e.target.value as string;
                setChangeData(prev => ({ ...prev, fiftyEGP: val }))

              }} style={MainInputStyle}
                colors={colors} theme={theme} />
            </div>
            {/* 100 جنيه */}
            <div className='max-w-[75px]'>
              <h6 className='mb-2'>100 جنيه</h6>
              <CustomTextFieldFullHeight height={25} id='hundredEGP' type={''} onChange={(e: any) => {
                let val = e.target.value as string;
                setChangeData(prev => ({ ...prev, hundredEGP: val }))

              }} style={MainInputStyle}
                colors={colors} theme={theme} />
            </div>
            {/* 200 جنيه */}
            <div className='max-w-[75px]'>
              <h6 className='mb-2'>200 جنيه</h6>
              <CustomTextFieldFullHeight height={25} id='TwoHundredEGP' type={''} onChange={(e: any) => {
                let val = e.target.value as string;
                setChangeData(prev => ({ ...prev, TwoHundredEGP: val }))

              }} style={MainInputStyle}
                colors={colors} theme={theme} />
            </div>
          </div>
          {/* الإجمالي */}
          <div className='w-full'>
            <h6 className='mb-2'>الإجمالي</h6>
            <CustomTextFieldFullHeight disabled value={total} height={25} id='PROJECT_NAME' type={''} onChange={(e: any) => {
              let val = e.target.value as string;

            }} style={MainInputStyle}
              colors={colors} theme={theme} />
          </div>
          <div className='justify-between flex flex-col gap-2 flex-wrap mt-5'>
            <h6 className='font-bold'>تاريخ الأيداع</h6>
            <EGDate DateValue={(dateVal) => {
              setDepDate(dateVal)
            }}
            />
          </div>
        </div>
      </div>
      {/* left top side*/}
      <div className='bg-white rounded shadow col-start-2 col-end-3 row-start-1 row-end-2' >
        {/* container */}
        <div className='p-3 flex flex-col gap-4'>
          {/* صرف إجباري */}
          {/* <div className='flex gap-3 items-center '>
            <input type="checkbox" id="uniqueLoc" checked={forceWithdraw} name="ProjType" className='w-[1em] h-[1em]' onChange={(e: any) => {
              let isChecked = e.target.checked
              setForceWithdraw(isChecked)
            }} />
            <label htmlFor='uniqueLoc' className='text-xl'>صرف إجباري</label>
          </div> */}
          {/* سبب الإيداع */}
          <div className='w-full flex flex-row gap-4 items-center'>
            <label className='text-base min-w-[80px]'>إيداع نظير</label>
            <DropTextField initalValue={moneySource} data={[
              { id: MONEY_SOURCE.UNKNOWN, desc: "اختر السبب" },
              { id: MONEY_SOURCE.DEAL_SOURCE, desc: "صفقه" },
              { id: MONEY_SOURCE.OTHER_SOURCE, desc: "اخرى" },

            ]} onchange={e => {
              let val = e.target.value as number
              setMoneySource(val)
            }} />
          </div>
          {/* الصندوق */}
          <div className='w-full flex flex-row gap-4 items-center'>
            <label className='text-base min-w-[80px]'>الصندوق</label>
            <DropTextField initalValue={-1} data={BoxesDropDown} onchange={e => {
              let val = e.target.value as number
              setSelectedBoxId(val)
            }} />
          </div>

          {/* سبب الصرف */}
          <div className='w-full flex flex-row gap-4 items-center'>
            <label className='text-base min-w-[80px]'>نوع الإيداع</label>
            <DropTextField initalValue={-5} data={[
              { id: -1, desc: "اختار النوع" },
              ...TransReasonDropDown
            ]} onchange={e => {
              let val = e.target.value as number
              setSelectedReason(val)
            }} />
          </div>

          {/* المستخدمين */}
          <div className='w-full flex flex-row gap-4 items-center'>
            <label className='text-base min-w-[80px]'>المستخدمين</label>
            <DropTextField initalValue={-1} data={[
              { id: -1, desc: "اختار مستخدم" }, ...userDropDown
            ]} onchange={e => {
              let val = e.target.value
              setSelectedUserId(val)
            }} />
          </div>
          {(moneySource === MONEY_SOURCE.DEAL_SOURCE || selectedBoxId === Boxes.CommissionBox) && (
            <>
              {/* الصفقات */}
              {selectedBoxId == Boxes.CommissionBox && (
                <>
                  <div className='w-1/4 md:w-1/2 flex flex-row gap-4 items-center'>
                    <EgButton HandleClick={() => GetUserDeals()}>
                      <h6>عرض الصفقات</h6>
                    </EgButton>
                  </div>
                  <hr />
                  <div>
                    <div>
                      {/* <h5 className='text-[#e7c498] mb-6'>صفقات تم صرف شيكها</h5>
                      <div>
                        {(userDeals.CollectedDeals !== null && userDeals.CollectedDeals !== undefined && userDeals.CollectedDeals.length > 0) && userDeals.CollectedDeals.map((item, idx) => {
                          let dealName = item.DEAL_ID + "/" + item.CONTACT_NAME + "/" + item.PROJECT_NAME
                          return <div key={item.DEAL_ID}>
                            <input type="checkbox" id={item.DEAL_ID!.toString()} name={item.CONTACT_NAME} className='w-[1em] h-[1em]' onChange={(e: any) => {
                              let isChecked = e.target.checked
                              if (isChecked === true) {
                                setSelectedDeals(prev => [...prev, { AMOUNT: item.AMOUNT, DESTINATION_ID: selectedBoxId, BENEFICIARY_USER_ID: selectedUserId, DEAL_ACTION_ID: item.DEAL_ID }])
                              }

                              if (isChecked === false) {
                                let otherItems = selectedDeals.filter(deals => deals.DEAL_ACTION_ID !== item.DEAL_ID);
                                if (otherItems.length > 0) {
                                  setSelectedDeals(otherItems)
                                }
                              }
                            }} />
                            <h6>{dealName}</h6>
                          </div>
                        })}
                      </div> */}
                    </div>
                    <hr />
                    <div>
                      <h5 className='text-[#e7c498] mb-6'>الصفقات</h5>
                      <div>
                        {(userDeals.UnCollectedDeals !== null && userDeals.UnCollectedDeals !== undefined && userDeals.UnCollectedDeals.length > 0) && userDeals.UnCollectedDeals.map((item, idx) => {
                          let dealName = item.DEAL_ID + " / " + item.CONTACT_NAME + " / " + item.PROJECT_NAME + " بمبلغ " + item.AMOUNT + " جنيه مصري "
                          return <div className='flex gap-5 mb-3' key={item.DEAL_ID}>
                            <input type="radio" id={item.DEAL_ID!.toString()} name={"SelectedDeal"} className='w-[1em] h-[1em]' onChange={(e: any) => {
                              setSelectedDeals(prev => [{ AMOUNT: item.AMOUNT, DESTINATION_ID: selectedBoxId, BENEFICIARY_USER_ID: selectedUserId, DEAL_ACTION_ID: item.DEAL_ID }])
                              // if (isChecked === true) {
                              //   setSelectedDeals(prev => [...prev, { AMOUNT: item.AMOUNT, DESTINATION_ID: selectedBoxId, BENEFICIARY_USER_ID: selectedUserId, DEAL_ACTION_ID: item.DEAL_ID }])
                              // }

                              // if (isChecked === false) {
                              //   let otherItems = selectedDeals.filter(deals => deals.DEAL_ACTION_ID !== item.DEAL_ID);
                              //   if (otherItems.length > 0) {
                              //     setSelectedDeals(otherItems)
                              //   }
                              // }
                            }} />
                            <h6>{dealName}</h6>
                          </div>
                        })}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
      {/* bottom full width side of grid*/}
      <div className='bg-white rounded shadow col-span-full h-60'>
        {/* container */}
        <div className='flex flex-col gap-5  p-3'>
          {/* ملاحظات */}
          <textarea id="Notes" name="otherNotes" className="col-span-2 resize-none w-full h-[10em] shadow-[0px] rounded-md border-black border focus:outline-none focus:shadow-none " placeholder='ملاحظات' onChange={(e: any) => {
            let val = e.target.value
            setNotes(val)
          }} >
          </textarea>
          <div className='self-end w-28'>
            <EgButton HandleClick={() => {
              DepositMoney()
            }}>
              <h6>إيداع</h6>
            </EgButton>
          </div>
        </div>
      </div>
      <div ref={EqualityRef} className='w-full h-full absolute top-0 flex justify-center items-center transition-all ease-in-out duration-[1000ms]'>
        <Equality dealAmount={selectedDeals[0]?.AMOUNT!.toString() ?? "0"} depositAmount={total} RejectFunction={hideEquality} AcceptFunction={() => DepositMoney(true)} />
      </div>
    </div>
  )
}

export default Deposit
