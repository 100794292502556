import React from 'react'

const NewProjectArch = () => {
    return (
        <div className='w-full h-[85vh] overflow-y-auto bg-white shadow'>
            
        </div>
    )
}

export default NewProjectArch