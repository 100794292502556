import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { GetErrorAxios } from "../../../Globals";
import { GetAllBankAccounts } from "../../../Service/Apis";
import DevExtremTable from "../../../components/shared/EGDExtremeTable";
import { setLoadingFalse, setLoadingTrue } from "../../../redux/slices/LoadingSlice";
import { AppDispatch } from "../../../redux/store";
import { UserModel } from "../../../Service/models/UserModel/UserModel";

const BankAccounts = () => {

    //states
    const [AllBankAccounts, setAllBankAccountss] = useState<UserModel[]>([]);
    const dispatch = useDispatch<AppDispatch>();



    // useEffect
    useEffect(() => {
        dispatch(setLoadingTrue())
        GetAllBankAccounts().then(res => {
            setAllBankAccountss(res);
            dispatch(setLoadingFalse())
        }).catch(err => {
            GetErrorAxios(err, dispatch)
        })
    }, []);



    return (
        <>
            <div>
                <DevExtremTable
                    ID="ID"
                    tableData={[
                        { Caption: "اسم المستخدم", Type: 'string', Value: 'FULL_NAME' },
                        { Caption: "البريد الإلكتروني", Type: 'string', Value: 'EMAIL' },
                        { Caption: "اسم البنك", Type: 'string', Value: 'BANK_NAME' },
                        { Caption: "رقم الحساب", Type: 'string', Value: 'BANK_ACCOUNT_NUMBER' },
                        { Caption: "رقم انستاباي", Type: 'string', Value: 'INSTAPAY_ACCOUNT' },
                        { Caption: "صورة البطاقة من الامام", Type: 'string', Value: 'NATIONAL_ID_IMAGE_URL' ,isImage:true},
                        { Caption: "صورة البطاقة من الخلف", Type: 'string', Value: 'NATIONAL_ID_BACK_IMAGE_URL' ,isImage:true},
                    ]}
                    dataSource={AllBankAccounts}
                />
            </div>
        </>
    );
};

export default BankAccounts;
