import DataGrid, { Column, Export, FilterRow, Pager, Paging, SearchPanel } from 'devextreme-react/cjs/data-grid';
import { ComponentProps, useEffect, useState } from 'react';
import { DateRegEx, DaysNames, exportFormats, exportGrid, getDateTimeOnly, getYearMonthDayFromDate } from '../../../Globals';

interface ITableProps<T> extends ComponentProps<'div'> {
    tableData: { Caption: string, Value: Extract<keyof T, string>, isImage?: boolean, Type: "string" | "number" | "boolean" | "object" | "date" | "datetime" | undefined }[];
    dataSource: T[];
    ID: Extract<keyof T, string>;
    Actions?: (currentRow: any) => JSX.Element;
    AllowPaging?: boolean;
    AllowExport?: boolean;
    AllowSearhTopBar?: boolean;
    Width?: number;
}

const CellFormat = (data: any, type: any, isImage?: boolean) => {
    let newValue = data.value;
    // Handle image rendering if isImage is true
    if (isImage) {
        return (
            <div className='flex justify-center items-center'>
                <a href={newValue} target="_blank" rel="noopener noreferrer">
                    <img src={newValue} alt="Image" style={{ width: '50px', height: '50px', borderRadius: '8px' }} />
                </a>
            </div>
        );
    }

    // Handle date formatting
    let isDate = DateRegEx.test(newValue as string);
    if (isDate) {
        return (
            <div dir='ltr' className='flex w-full h-12 justify-center items-center'>
                <h6 className='text-bold truncate'>
                    <span>{DaysNames[new Date(newValue).getDay()]}</span> -
                    <span className='font-bold'> {getYearMonthDayFromDate(new Date(newValue))} </span> -
                    <span className='font-bold'>{getDateTimeOnly(new Date(newValue))}</span>
                </h6>
            </div>
        );
    }

    // Handle boolean formatting
    if (type === 'boolean') {
        return newValue === true ? (
            <div className='w-full h-12 flex justify-center items-center'>
                <h5 className='font-bold text-red-500'>نعم</h5>
            </div>
        ) : (
            <div className='w-full h-12 flex justify-center items-center'>
                <h5 className='font-bold text-green-500'>لا</h5>
            </div>
        );
    }

    // Default text rendering
    return (
        <div dir='ltr' className='flex w-full h-12 justify-center items-center'>
            <h6 className='text-bold truncate'>{newValue ?? "-"}</h6>
        </div>
    );
};

const DevExtremTable = <T extends object>(Props: ITableProps<T>): JSX.Element => {
    const { ID, tableData, dataSource, Actions, AllowPaging, AllowExport, AllowSearhTopBar } = Props;

    const [RID, setRID] = useState<string>("");
    const [RtableData, setRtableData] = useState<{ Caption: string, Value: Extract<keyof T, string>, isImage?: boolean, Type: "string" | "number" | "boolean" | "object" | "date" | "datetime" | undefined }[]>([]);
    const [RdataSource, setRdataSource] = useState<T[]>([]);

    useEffect(() => {
        setRID(ID);
        setRtableData(tableData);
        setRdataSource(dataSource);
    }, [ID, tableData, dataSource, Actions, AllowPaging, AllowExport, AllowSearhTopBar]);

    return (
        <div dir='rtl'>
            <DataGrid rtlEnabled={true} id="dataGrid" dataSource={RdataSource} keyExpr={RID} allowColumnReordering={true} rowAlternationEnabled={true} scrolling={{ scrollByThumb: true, renderAsync: true }} columnAutoWidth={true} onExporting={exportGrid}>
                {RtableData.map((item, idx) => (
                    <Column
                        key={idx}
                        alignment='center'
                        dataField={item.Value}
                        dataType={item.Type}
                        caption={item.Caption}
                        cellRender={(data) => CellFormat(data, item.Type, item.isImage)}
                    />
                ))}
                {Actions !== undefined && (
                    <Column alignment='center' dataField="" dataType='string' caption='الإجرائات' allowFiltering={false} type='buttons' allowExporting={false} width={Props.Width} cellRender={Props.Actions}></Column>
                )}

                <FilterRow visible={true} />
                {(AllowSearhTopBar !== undefined || AllowSearhTopBar === true) && <SearchPanel visible={true} />}
                {(AllowPaging !== undefined || AllowPaging === true) && <Paging defaultPageSize={50} />}
                <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20, 50]} showInfo={true} />
                {(AllowExport !== undefined || AllowExport === true) && <Export enabled={true} formats={exportFormats} />}
            </DataGrid>
        </div>
    );
};

export default DevExtremTable;
