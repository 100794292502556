import { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetErrorAxios, HavePermission, PERMISSIONS, sleep } from '../../../Globals';
import { CancelDeal, GET_ALL_DEALS, MarkDealContractToBeDone } from '../../../Service/Apis';
import { ActionID } from '../../../Service/models/CancelReq';
import { COMMISSIONS, DEAL_MODEL } from '../../../Service/models/DealApplication';
import { ProjectModel } from '../../../Service/models/projects/ProjectModel';
import { Cancelbox, CalculatorLogo, PercentageLogo, ViewLogo, ContractIcon } from '../../../components/Icons/Icons';
import DevExtremTable from '../../../components/shared/EGDExtremeTable';
import { AddAction } from '../../../redux/slices/ErrorsSlice';
import { setLoadingFalse, setLoadingTrue } from '../../../redux/slices/LoadingSlice';
import { UsersState } from '../../../redux/slices/UserSlice';
import { AppDispatch } from '../../../redux/store';
import AccountantAction from '../../Accountant';
import DDeal from '../../DealDisplay';
import Percentage from '../Actions/ActionsTypesScreen/Percentage';
import ConfirmationModal from '../Actions/ActionsTypesScreen/ConfirmationModal';
import './style.css';
import GModal from '../../../components/GModal';



const DealsActions = () => {

    const dispatch = useDispatch<AppDispatch>();


    const dealActionRef = useRef<HTMLDivElement>(null);
    const displayDealActionRef = useRef<HTMLDivElement>(null);
    const displayDeal = useRef<HTMLDivElement>(null);
    const AccountantActions = useRef<HTMLDivElement>(null);
    const UsersData = useSelector(UsersState);
    const [emptyComm, setEmptyComm] = useState<COMMISSIONS>({} as COMMISSIONS)
    const [projects, setProjects] = useState<ProjectModel[]>([])

    const [confirmDel, setConfirmDel] = useState<boolean>(false)

    const [commEdit, setCommEdit] = useState<boolean>(false)
    const [commView, setCommView] = useState<boolean>(false)


    const hideDealModel = (refObj: RefObject<HTMLDivElement>) => {
        if (refObj.current) {
            refObj.current.classList.add('hide')
            refObj.current.classList.remove('show')
        }
    }

    const showDealModel = (refObj: RefObject<HTMLDivElement>) => {
        if (refObj.current) {
            refObj.current.classList.add('show')
            refObj.current.classList.remove('hide')
        }
    }

    const StoredAPI = useCallback(() => {
        // getAllProjects().then(res => {
        //     setProjects(res)
        // }).catch(err => {
        //     GetErrorAxios(err, dispatch);
        // })
    }, [])

    const GETAllDealsFromAPI = () => {
        GET_ALL_DEALS().then((res) => {
            // setDeals([res[0],res[1]])
            setDeals(res)
            // 
        }).catch((err) => {
            GetErrorAxios(err, dispatch)
        })
    }



    useEffect(() => {
        // hideDealModel()
        GETAllDealsFromAPI()
        StoredAPI()

    }, [])


    const [deals, setDeals] = useState<Partial<DEAL_MODEL>[]>([])

    const [selectedDeal, setSelectedDeal] = useState<DEAL_MODEL>({} as DEAL_MODEL)

    const fetchCancelDeal = async (id: ActionID) => {
        dispatch(setLoadingTrue())
        const data = await CancelDeal(id);
        dispatch(setLoadingFalse())

        if (data === true) {
            dispatch(AddAction({ status: 'success', message: 'تم حذف الديل بنجاح' }))
            await sleep(2 * 1000)
            window.location.reload()
        }


        if (data === false) {
            dispatch(AddAction({ status: 'error', message: 'حدث خطأ اثناء الحذف' }))
        }
    };

    const DeleteDeal = () => {
        if (selectedID.ACTION_ID !== undefined) {
            fetchCancelDeal(selectedID);
        }
    }




    // Boxes Ids 
    const [selectedID, setSelectedID] = useState<ActionID>({} as ActionID);

    // useEffect(() => {

    // }, [selectedID]);



    const [isContractModalOpen, setIsContractModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleContractClick = (data: DEAL_MODEL) => {
        setIsContractModalOpen(true);
        setSelectedDeal(data)
    };

    const handleContractConfirm = () => {
        setIsLoading(true);
        console.log(selectedDeal)
        MarkDealContractToBeDone({ ACTION_ID: selectedDeal.ACTION_ID! })
            .then((res) => {
                dispatch(AddAction({ status: 'success', message: 'تم تقديم المطالبه بنجاح' }));
                window.location.reload();
            })
            .catch((err) => {
                GetErrorAxios(err, dispatch);
            })
            .finally(() => {
                setIsLoading(false);
                setIsContractModalOpen(false);
            });
    };

    const handleContractCancel = () => {
        setIsContractModalOpen(false);
    };

    return (
        <>

            <div>
                <DevExtremTable ID="ACTION_ID" tableData={[
                    { Caption: "الفرع", Type: 'string', Value: 'STATION_NAME' },
                    { Caption: "كود الاستمارة", Type: 'string', Value: 'ACTION_ID' },
                    { Caption: "مقدم الطلب", Type: 'string', Value: 'APPLICANT_NAME' },
                    { Caption: "اسم العميل", Type: 'string', Value: 'CLIENT_NAME' },
                    { Caption: "شركة التطوير", Type: 'string', Value: 'COMPANY_NAME' },
                    { Caption: "المشروع", Type: 'string', Value: 'COMPANY_PROJECT_NAME' },
                    { Caption: "سعر الوحده", Type: 'string', Value: 'CLIENT_UNIT_PRICE' },
                    { Caption: "توزيع النسب", Type: 'boolean', Value: 'HAS_COMMISSSION' },
                    { Caption: "تعاقد", Type: 'boolean', Value: 'CONTRACT_DONE' },
                ]}
                    dataSource={deals}
                    Actions={(data) => {
                        let currentAction = data.data as DEAL_MODEL;
                        return (
                            <div className='flex flex-row flex-wrap w-full h-full gap-1 justify-around'>
                                {(currentAction.CONTRACT_DONE === undefined || currentAction.CONTRACT_DONE === null || currentAction.CONTRACT_DONE === false) && (
                                    <div className='flex flex-col items-center hover:text-[#e7c498] hover:cursor-pointer' onClick={() => handleContractClick(currentAction) }>
                                        <div className='bg-[#e2edf1] rounded shadow h-6 w-6 p-1'>
                                            <ContractIcon />
                                        </div>
                                        <h6 className='text-xs font-bold my-2'>تعاقد</h6>
                                    </div>
                                )}
                                {UsersData.currentUser.PERMISSIONS && HavePermission(PERMISSIONS.SAVE_COMMISSION, UsersData.currentUser.PERMISSIONS) && currentAction.DEAL_COMMISION === undefined && (
                                    <div className='flex flex-col items-center hover:text-[#e7c498] hover:cursor-pointer' onClick={() => {
                                        setSelectedDeal(currentAction);
                                        showDealModel(dealActionRef);
                                        setCommEdit(false);
                                        setCommView(false);

                                    }}>
                                        <div className='bg-[#e2edf1] rounded shadow h-6 w-6 p-1'>
                                            <PercentageLogo />
                                        </div>
                                        <h6 className='text-xs font-bold my-2'>توزيع النسب</h6>
                                    </div>
                                )}
                                {UsersData.currentUser.PERMISSIONS && HavePermission(PERMISSIONS.SAVE_COMMISSION, UsersData.currentUser.PERMISSIONS) && currentAction.DEAL_COMMISION !== undefined && (
                                    <div className='flex flex-col items-center hover:text-[#e7c498] hover:cursor-pointer' onClick={() => {
                                        console.log(currentAction)

                                        setSelectedDeal(currentAction);
                                        showDealModel(dealActionRef);
                                        setCommEdit(true);
                                        setCommView(false);

                                    }}>
                                        <div className='bg-[#e2edf1] rounded shadow h-6 w-6 p-1'>
                                            <PercentageLogo />
                                        </div>
                                        <h6 className='text-xs font-bold my-2'>تعديل النسب</h6>
                                    </div>
                                )}
                                {(currentAction.DEAL_COMMISION !== undefined) && (
                                    <div className='flex flex-col items-center hover:text-[#e7c498] hover:cursor-pointer' onClick={() => {
                                        // setSelectedDeal({} as DEAL_MODEL);
                                        setCommEdit(false);
                                        setCommView(true);
                                        setSelectedDeal(currentAction);
                                        showDealModel(displayDealActionRef);
                                    }}>
                                        <div className='bg-[#e2edf1] rounded shadow h-6 w-6'>
                                            <ViewLogo />
                                        </div>
                                        <h6 className='text-xs font-bold my-2'>عرض النسب</h6>
                                    </div>
                                )}
                                <div className='flex flex-col items-center hover:text-[#e7c498] hover:cursor-pointer' onClick={() => {
                                    setCommView(false);
                                    setCommEdit(false);
                                    setSelectedDeal(currentAction);
                                    showDealModel(displayDeal);
                                }}>
                                    <div className='bg-[#e2edf1] rounded shadow h-6 w-6 p-1'>
                                        <ViewLogo />
                                    </div>
                                    <h6 className='text-xs font-bold my-2'>عرض الديل</h6>
                                </div>
                                {UsersData.currentUser.PERMISSIONS && HavePermission(PERMISSIONS.ActionDeal, UsersData.currentUser.PERMISSIONS) && (
                                    <div className='flex flex-col items-center hover:text-[#e7c498] hover:cursor-pointer' onClick={() => {
                                        setSelectedDeal(currentAction);
                                        showDealModel(AccountantActions);
                                    }}>
                                        <div>
                                            <div className='bg-[#e2edf1] rounded shadow h-6 w-6 p-1'>
                                                <CalculatorLogo />
                                            </div>
                                            <h6 className='text-xs font-bold my-2'>حسابات</h6>
                                        </div>

                                    </div>
                                )}
                                {UsersData.currentUser.PERMISSIONS && HavePermission(PERMISSIONS.editDeals, UsersData.currentUser.PERMISSIONS) && (
                                    <div className='flex flex-col items-center hover:text-[#e7c498] hover:cursor-pointer' onClick={() => {
                                        setSelectedID({ ACTION_ID: currentAction.ACTION_ID! })
                                        setConfirmDel(true)

                                    }}>
                                        <div>
                                            <div className='bg-[#e2edf1] rounded shadow h-6 w-6 p-1'>
                                                <Cancelbox />
                                            </div>
                                            <h6 className='text-xs font-bold my-2'>حذف</h6>
                                        </div>
                                    </div>
                                )}
                            </div>
                        );
                    }}
                    Width={350}
                    AllowPaging={true} />
                <div>
                    <div ref={dealActionRef} className='transition-all duration-1000 ease-in-out hide fixed top-[12.5%] rounded-2xl shadow-xl  w-3/4 bg-slate-100 z-[100] overflow-hidden'>
                        <div className='w-full h-full relative'>
                            <Percentage isEdit={commEdit} isView={commView} deal={selectedDeal} clearCommission={() => {
                                hideDealModel(dealActionRef);
                                setSelectedDeal({} as DEAL_MODEL);
                            }} />
                        </div>
                    </div>
                    <div ref={displayDealActionRef} className='transition-all duration-1000 ease-in-out hide fixed top-[12.5%] rounded-2xl shadow-xl  w-3/4 bg-slate-100 z-[100] overflow-hidden'>
                        <div className='w-full h-full relative'>
                            <Percentage isEdit={commEdit} isView={commView} deal={selectedDeal} clearCommission={() => {
                                hideDealModel(displayDealActionRef);
                                setSelectedDeal({} as DEAL_MODEL);
                            }} />
                            <div className='absolute top-4 left-4 ' onClick={() => {
                            }}>
                            </div>
                        </div>
                    </div>
                    <div ref={displayDeal} className='transition-all duration-1000 ease-in-out hide h-[95vh] fixed top-[25px] rounded-2xl shadow-xl  w-3/4 bg-slate-100 z-[100] overflow-hidden'>
                        <div className='w-full h-full relative'>
                            <DDeal deal={selectedDeal} />
                            <div className='absolute top-4 left-4 i-material-symbols-close-rounded text-xl text-red-500 hover:cursor-pointer' onClick={() => {
                                hideDealModel(displayDeal);
                                setSelectedDeal({} as DEAL_MODEL);
                            }}>
                            </div>
                        </div>
                    </div>
                    <div ref={AccountantActions} className='transition-all duration-1000 ease-in-out hide fixed top-[12.5%] rounded-2xl shadow-xl  w-3/4 bg-slate-100 z-[100] overflow-hidden'>
                        <div className='w-full h-full relative'>
                            <AccountantAction deal={selectedDeal} />
                            <div className='absolute top-4 left-4 i-material-symbols-close-rounded text-xl text-red-500 hover:cursor-pointer' onClick={() => hideDealModel(AccountantActions)}>
                            </div>
                        </div>
                    </div>
                </div>
                <GModal onCloseModal={() => {
                    setConfirmDel(false)
                }} tailwindHeight='h-[30vh]' OpenModal={confirmDel} >
                    <div>
                        <h3>هل انت متأكد انك تريج حذف الديل</h3>
                        <div className='flex justify-around'>
                            <div>
                                <button className='bg-red-500 text-white px-4 py-2 rounded-lg hover:cursor-pointer w-28' onClick={() => {
                                    setConfirmDel(false)

                                }}>لا</button>
                            </div>
                            <div>
                                <button className='bg-green-500 text-white px-4 py-2 rounded-lg hover:cursor-pointer w-28' onClick={() => {
                                    DeleteDeal()
                                }}>حذف</button>
                            </div>
                        </div>
                    </div>
                </GModal>
                <ConfirmationModal
                    isOpen={isContractModalOpen}
                    onClose={handleContractCancel}
                    onConfirm={handleContractConfirm}
                    title="تقدم الشركه المطالبه لشركه التطوير ؟"
                    description="  هل أنت متأكد أنك تريد أن تقوم الشركة بإجراءات المطالبة؟  في حاله تقديم المطالبه لشركه التطوير ويتم ردها لعدم اتمام اجراءات التعاقد يتم خصم ٢٠٠٠ جنيه علي الشخص مصروفات اداريه وانتقالات بشكل خاطئ"
                    isLoading={isLoading}
                />
            </div>
        </>
    )
}

export default DealsActions
