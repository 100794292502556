// hoc/withAuth.tsx
import React, { ComponentType } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { CookiesData, getCookies } from '../Globals';
import { UsersState } from '../redux/slices/UserSlice';
import { useSelector } from 'react-redux';


export interface WithAuthProps {
  test?: string;
}

const withAuth = <P extends object>(WrappedComponent: ComponentType<P>) => {
  const ComponentWithAuth: React.FC<P & WithAuthProps> = (props) => {
    const UsersData = useSelector(UsersState);
    const isAuthenticated = getCookies(CookiesData.LoggedIn)
    const navigate = useNavigate();
    const windowLocation = window.location.pathname;

    if (!isAuthenticated) {
      // Redirect to login if the user is not authenticated
      // window.location.href = '/Login';
      navigate('/Login');
      return null;
    }



    if (UsersData && UsersData.currentUser.NEED_UPDATE === true && windowLocation !== '/dashboard/UpdateCustomerData')  {
      window.location.href = '/dashboard/UpdateCustomerData';
      // navigate('/dashboard/UpdateCustomerData');
      return null;
    }

    // Render the wrapped component if authenticated
    return <WrappedComponent {...props} />;
  };

  return ComponentWithAuth;
};

export default withAuth;
