export interface TRANSACTIONS {
    ID?: number;
    SOURCE_TYPE?: MONEY_SOURCE;
    TRANSACTION_CATG: TRANSACTION_CATG;
    TRANSACTION_TYPE_DESC?: string;
    TRANSACTION_TYPE_ID?: number;
    FORCE_TRANSACTION?: boolean;
    WITHDRAW_AMT?: string,
    DEPOSIT_AMT?: string,
    AMOUNT: number;
    COUNT_1: number;
    COUNT_5: number;
    COUNT_10: number;
    COUNT_20: number;
    COUNT_50: number;
    COUNT_100: number;
    COUNT_200: number;
    NOTES?: string;
    STAMP_DATE?: Date;
    STAMP_USER?: string;
    TRANSACTION_DETALS?: TRANSACTION_DETALS[];
    EFFECT_DATE?: Date;
    IN_BANK?: boolean;
    BANK_PHOTO_BASE64?: string;
    BANK_PHOTO_URL?: string;
    BANK_PHOTO_BYTES?: number[];
    BENEFICIARY_USER_NAME?: string;
}

export interface TRANSACTION_DETALS {
    TRANSACTION_ID?: number;
    DESTINATION_ID: number;
    DEAL_ACTION_ID?: number;
    BENEFICIARY_USER_ID?: number;
    AMOUNT?: number;
    MONTH?: number;
    YEAR?: number;
    EVENT_ID?: number;
    TRANSPORTATION_ACTION_ID?: number
}



export enum MONEY_SOURCE {
    UNKNOWN = -1,
    DEAL_SOURCE = 1,
    BOX_SOURCE = 2,
    OTHER_SOURCE = 3
}

export enum TRANSACTION_CATG {
    CREDIT_CATG = 1,
    DEBIT_CATG = 2,
    TRANSFER = 3
}


export interface TRANSACTION_TYPES {
    ID: number;
    DESCRIPTION: string;
    DESCRIPTION_EN: string;
    IS_DEAL: boolean;
    IS_BOX: boolean;
    IS_OTHER: boolean;
    IS_SALARY: boolean;
    IS_EVENT: boolean;
    IS_PROFIT: boolean;
    IS_TRANSPORTATION: boolean;
}

export type UserDeals = {
    CollectedDeals?: CompDeal[]
    UnCollectedDeals?: CompDeal[]
}

export type CompDeal = {
    DEAL_ID: number
    CONTACT_NAME: string,
    PROJECT_NAME: string,
    AMOUNT: number
}


export interface CashBoxBalance {
    AMOUNT: number;
    BoxesBalance: Record<number, number>

    AMOUNT_BANK: number;
    BankBoxesBalance: Record<number, number>

    COUNT_1: number;
    COUNT_5: number;
    COUNT_10: number;
    COUNT_20: number;
    COUNT_50: number;
    COUNT_100: number;
    COUNT_200: number;
}