import React, { useEffect, useState } from 'react'
import { CustomTextField, CustomTextFieldFullHeight } from '../../../CustomComps/Customs'
import { tokens } from '../../../theme';
import { useTheme } from '@mui/material';
import { DigitValidate, GetErrorAxios } from '../../../Globals';
import { KYC_Request } from '../../../Service/models/KYC/KYC';
import { DropTextField } from '../Actions/ActionsTypesScreen/AppointMents';
import { convertToBase64, EgButton } from '../../Shared/Shared';
import { GetAllBanks, SaveCustomerData } from '../../../Service/Apis';
import { BANKS } from '../../../Service/models/Banks/banks';
import { useDispatch, useSelector } from 'react-redux';
import { ImageType } from '../Projects/projectList';
import { AddAction } from '../../../redux/slices/ErrorsSlice';
import { setLoadingFalse, setLoadingTrue } from '../../../redux/slices/LoadingSlice';
import { UsersState } from '../../../redux/slices/UserSlice';

const Updatecustomerdata = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const UsersData = useSelector(UsersState);
    const MainInputStyle = { marginBottom: 6, marginTop: 3, width: '100%', backgroundColor: 'white' };

    const dispatch = useDispatch()

    const [Req, setReq] = useState<KYC_Request>({
        BANK_ACCOUNT_NUMBER: UsersData.currentUser.BANK_ACCOUNT_NUMBER,
        BANK_ID: UsersData.currentUser.BANK_ID,
        FULL_NAME: UsersData.currentUser.FULL_NAME?.replace(/\+/g, ' '),
        FULL_NAME_EN: UsersData.currentUser.FULL_NAME_EN?.replace(/\+/g, ' '),
        INSTAPAY_ACCOUNT: UsersData.currentUser.INSTAPAY_ACCOUNT,
        NATIONAL_ID: UsersData.currentUser.NATIONAL_ID,
        NATIONAL_ID_BACK_IMAGE_URL: UsersData.currentUser.NATIONAL_ID_BACK_IMAGE_URL,
        NATIONAL_ID_IMAGE_URL: UsersData.currentUser.NATIONAL_ID_IMAGE_URL,
    } as KYC_Request)
    const [Banks, setBanks] = useState<BANKS[]>([])
    const [hasFrontImage, setHasFrontImage] = useState<boolean>(UsersData.currentUser.NATIONAL_ID_IMAGE_URL ? true : false)
    const [hasBackImage, sethasBackImage] = useState<boolean>(UsersData.currentUser.NATIONAL_ID_BACK_IMAGE_URL ? true : false)

    const ChangeReq = (val: keyof KYC_Request, value: any) => {
        setReq(prev => ({ ...prev, [val]: value }))
    }

    const [frontIdImage, setFrontIdImage] = useState<string | null>(null);
    const [backIdImage, setBackIdImage] = useState<string | null>(null);

    const onFrontIDUpload = async (e: any) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            const file = files[0];
            let data = await convertToBase64(file) as string;
            ChangeReq('NATIONAL_ID_BASE64', data);
            setFrontIdImage(data);
        }
    }
    const onBackIDUpload = async (e: any) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            const file = files[0];
            let data = await convertToBase64(file) as string;
            ChangeReq('NATIONAL_ID_BACK_BASE64', data);
            setBackIdImage(data);
        }
    }


    const handleSave = () => {
        dispatch(setLoadingTrue())
        SaveCustomerData(Req).then(res => {
            dispatch(setLoadingFalse())
            dispatch(AddAction({
                message: 'تم تحديث البيانات بنجاح',
                status: 'success'
            }))

        }).catch(err => {
            dispatch(setLoadingFalse())
            GetErrorAxios(err, dispatch)
        })
    }

    // <CustomTextFieldFullHeight height={25} id='DeveloeprCommision' type={''} onChange={(e: any) => {
    //     let val = e.target.value as string;

    //     if (val.match(DigitValidate)) {

    //     }
    // }} style={MainInputStyle}
    //     colors={colors} theme={theme} />

    useEffect(() => {
        GetAllBanks().then(res => {
            setBanks(res)
        }).catch(err => {
            GetErrorAxios(err, dispatch)
        })
    }, [])


    return (
        <div className='w-full h-full flex flex-col bg-slate-50 shadow-md p-5 rounded-md'>
            <h4 className='text-2xl font-bold'>برجاء تحديث البيانات</h4>
            <hr className='my-5' />
            <div className='w-full h-full grid grid-cols-[repeat(auto-fill,minmax(200px,1fr))] gap-5'>
                <div className='col-span-2'>
                    <label htmlFor="FullName" className='text-sm'>الاسم (مطابق للإسم في البطاقه)</label>
                    <CustomTextFieldFullHeight height={25} id='FullName' type={''} defaultValue={UsersData.currentUser.FULL_NAME?.replace(/\+/g, ' ')} onChange={(e: any) => {
                        let val = e.target.value as string;
                        ChangeReq('FULL_NAME', val)
                    }} style={MainInputStyle}
                        colors={colors} theme={theme} />
                </div>
                <div className='col-span-2'>
                    <label htmlFor="FullNameEn" className='text-sm'>الاسم بالانجليزي</label>
                    <CustomTextFieldFullHeight height={25} id='FullNameEn' defaultValue={UsersData.currentUser.FULL_NAME_EN?.replace(/\+/g, ' ')} type={''} onChange={(e: any) => {
                        let val = e.target.value as string;
                        ChangeReq('FULL_NAME_EN', val)
                    }} style={MainInputStyle}
                        colors={colors} theme={theme} />
                </div>
                <div className='col-span-2'>
                    <label htmlFor="NationalId" className='text-sm'>الرقم القومي</label>
                    <CustomTextFieldFullHeight height={25} id='NationalId' defaultValue={UsersData.currentUser.NATIONAL_ID} type={''} onChange={(e: any) => {
                        let val = e.target.value as string;
                        ChangeReq('NATIONAL_ID', val)
                    }} style={MainInputStyle}
                        colors={colors} theme={theme} />
                </div>
                <div className='col-span-2 flex flex-col '>
                    <label htmlFor="BankId" className='text-sm'>البنك</label>
                    <DropTextField initalValue={UsersData.currentUser.BANK_ID ?? 0} data={[{ id: 0, desc: "إختر بنك" }, ...Banks.map(bank => ({
                        id: bank.ID ?? 0,
                        desc: bank.BANK_NAME ?? ''
                    }))]} onchange={e => {
                        console.log(e.target.value)
                        ChangeReq('BANK_ID', e.target.value)
                    }} />
                </div>
                <div className='col-span-2'>
                    <label htmlFor="BankAccountNumber" className='text-sm'>رقم الحساب</label>
                    <CustomTextFieldFullHeight height={25} id='BankAccountNumber' type={''} defaultValue={UsersData.currentUser.BANK_ACCOUNT_NUMBER} onChange={(e: any) => {
                        let val = e.target.value as string;
                        ChangeReq('BANK_ACCOUNT_NUMBER', val)
                    }} style={MainInputStyle}
                        colors={colors} theme={theme} />
                </div>
                <div className='col-span-2'>
                    <label htmlFor="InstapayAccount" className='text-sm'>رقم الإنستاباي ان وجد</label>
                    <CustomTextFieldFullHeight height={25} id='InstapayAccount' type={''} defaultValue={UsersData.currentUser.INSTAPAY_ACCOUNT} onChange={(e: any) => {
                        let val = e.target.value as string;
                        ChangeReq('INSTAPAY_ACCOUNT', val)
                    }} style={MainInputStyle}
                        colors={colors} theme={theme} />
                </div>

            </div>
            <div className='w-full h-full flex gap-5'>
                <div className='grow h-52 border-[5px] border-gray-600 border-dashed mt-10 rounded-lg flex justify-center items-center gap-4 relative'>
                    {frontIdImage ? (
                        <img src={frontIdImage} alt="Front ID" className="w-full h-full object-contain" />
                    ) : (hasFrontImage ? (
                        <img src={UsersData.currentUser.NATIONAL_ID_IMAGE_URL} alt="Front ID" className="w-full h-full object-contain" />
                    ) :
                        <>
                            <label htmlFor="thumpImgs">
                                <div className='i-material-symbols-add-a-photo-outline-rounded text-6xl text-gray-500 hover:cursor-pointer'>
                                </div>
                            </label>
                            <h3>الصوره الأماميه للبطاقه</h3>
                        </>
                    )
                    }
                    <input style={{ visibility: 'hidden', display: 'none' }} type="file" accept="image/*" id="thumpImgs" onChange={onFrontIDUpload} />
                    {(frontIdImage || hasFrontImage) && (
                        <button
                            className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-1"
                            onClick={() => {
                                setFrontIdImage(null);
                                setHasFrontImage(false)
                                ChangeReq('NATIONAL_ID_BASE64', '');
                            }}
                        >
                            <span className="i-material-symbols-close"></span>
                        </button>
                    )}
                </div>
                <div className='grow h-52 border-[5px] border-gray-600 border-dashed mt-10 rounded-lg flex justify-center items-center gap-4 relative'>
                    {backIdImage ? (
                        <img src={backIdImage} alt="Back ID" className="w-full h-full object-contain" />
                    ) : (hasBackImage ? (
                        <img src={UsersData.currentUser.NATIONAL_ID_BACK_IMAGE_URL} alt="Front ID" className="w-full h-full object-contain" />
                    ) :
                        <>
                            <label htmlFor="thumpImgsBack">
                                <div className='i-material-symbols-add-a-photo-outline-rounded text-6xl text-gray-500 hover:cursor-pointer'>
                                </div>
                            </label>
                            <h3>الصوره الخلفيه للبطاقه</h3>
                        </>
                    )
                    }
                    <input style={{ visibility: 'hidden', display: 'none' }} type="file" accept="image/*" id="thumpImgsBack" onChange={onBackIDUpload} />
                    {(backIdImage || hasBackImage) && (
                        <button
                            className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-1"
                            onClick={() => {
                                setBackIdImage(null);
                                ChangeReq('NATIONAL_ID_BACK_BASE64', '');
                                sethasBackImage(false)
                            }}
                        >
                            <span className="i-material-symbols-close"></span>
                        </button>
                    )}
                </div>
            </div>
            <div className='w-[6rem] h-[2em] mt-6 self-end'>
                <EgButton HandleClick={handleSave}>
                    <h6 className='text-sm'>حفظ</h6>
                </EgButton>
            </div>
        </div>
    )
}

export default Updatecustomerdata




